import Me from "./Me";
import Organization from "./Organization";
import userService from "./UserService";
import msalService from "./msal-service";

class AuthService {
  me: Me | undefined = undefined;
  organization: Organization | undefined = undefined;

  ready: boolean = false;
  error: boolean = false;
  errorCode: number = 0;

  public getMe(): Me | undefined {
    return this.me;
  }

  public getOrganization(): Organization | undefined {
    return this.organization;
  }

  public isReady(): boolean {
    return this.ready;
  }

  public setError(code: number) {
    this.error = true;
    this.errorCode = code;
  }

  public async initialize(callback: any) {
    await msalService.initialize(callback);
  }

  public async autoLogin() {
    const msalAccount = msalService.getAccount();
    if (msalAccount) {
      const token = await msalService.getToken();
      localStorage.setItem("token", token ?? "");
      //console.log("token", token);

      if (token) {
        try {
          this.me = await userService.getMe();
          // console.log("AuthService.autoLogin() --> me", this.me);
        } catch (error) {
          console.error("autoLogin error", error);
        }
      } else {
        console.log("AuthService.autoLogin() --> No MSAL Account");
      }
    } else {
      console.log("AuthService.autoLogin() --> No token");
    }

    if (this.me) {
      this.organization = this.me.organization;
    }

    this.ready = true;
  }

  public async login() {
    await msalService.login();
    // await this.autoLogin();
  }

  public async signup() {
    await msalService.signup();
    // await this.autoLogin();
  }

  public async logout(redirectUrl: string): Promise<void> {
    localStorage.setItem("token", "");

    await msalService.logout(redirectUrl);
  }
}

const authService = new AuthService();
export default authService;
