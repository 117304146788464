import { useEffect, useState } from "react";
import useUploadDocuments from "../../services/Documents/useUploadDocuments";
import { DocumentUpload } from "../Documents/DocumentUpload";
import Document from "../../services/Documents/Document";
import i18n from "../../i18n";
import logger from "../../services/Logger";
import documentHelper, { DocumentFormatType } from "../../services/Documents/DocumentHelper";
import PropsDynamicField from "./PropsDynamicField";

export default function DynamicFieldImageList(props: PropsDynamicField) {
  const [documentIds, setDocumentIds] = useState<Array<string>>([]);

  useEffect(() => {
    if (props.data && documentIds.length === 0) {
      let value = props.data[props.field.id] as string;
      //console.log("DynamicFieldImageList", "useEffect", value);
      if (value) {
        setDocumentIds(value.split(","));
      }
    }
  }, [props.data]);

  const iUploadDocument = useUploadDocuments({
    organizationId: props.organizationId,
    defaultDocuments: documentIds,
    onChange: update,
  });

  return (
    <DocumentUpload
      title={props.field.label[i18n.language]}
      iUploadDocument={iUploadDocument}
      accepts={documentHelper.getFormats(DocumentFormatType.Image).join(",")}
      allowUpload={true}
      error={props.field.required && props.validated && props.error}
    />
  );

  function update(documents: Array<Document>) {
    logger.logTrace("DynamicFieldImageList", "update", documents);
    let value = documents.map((x) => x.id).join(",");
    props.update(props.field.id, value);
  }
}
