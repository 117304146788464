import { Box, Typography } from "@mui/material";
import Footer from "./Footer";
import AppBarMenu from "./AppBarMenu";
import { useSessionContext } from "../contexts/SessionContext";
import i18n from "../i18n";

export default function Layout({ children }: React.PropsWithChildren<any>) {
  const { organization } = useSessionContext();

  const bandeau = organization?.bandeau;
  const bandeauText = bandeau?.text[i18n.language];

  return (
    <>
      {bandeauText && bandeauText.length > 0 && (
        <Box textAlign="center" p={2} bgcolor={bandeau.bgColor} color={bandeau.color}>
          <Typography variant="body2">{bandeauText}</Typography>
        </Box>
      )}
      <AppBarMenu />

      <Box>{children}</Box>

      <Footer />
    </>
  );
}
