import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useConsultContext } from "./ConsultContext";
import i18n from "../../i18n";
import FormatHelpers from "../../services/FormatHelper";

export default function ConsultTabAdmin() {
  const { t } = useTranslation();

  const { consult } = useConsultContext();

  const payment = consult?.payment;
  const paymentLink = payment ? `https://dashboard.stripe.com/payments/${payment.externalId}` : "";

  return (
    <Table>
      <TableBody>
        {consult?.patientEmail && (
          <>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: "bold", fontSize: "1.1em" }}>
                {t("consult.patient-informations")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("common.email")}</TableCell>
              <TableCell>{consult.patientEmail}</TableCell>
            </TableRow>
          </>
        )}

        <TableRow>
          <TableCell colSpan={2} sx={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {t("consult.payment")}
          </TableCell>
        </TableRow>

        {payment ? (
          <>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>{payment.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>{t(`payment-state.${payment.state}`)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card</TableCell>
              <TableCell>
                {payment.cardBrand} {payment.cardLast4}, exp. {payment.cardExpiration}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>{FormatHelpers.formatMoney(payment.amount, i18n.language)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>External Payment Id</TableCell>
              <TableCell>
                <a target="_blank" href={paymentLink}>
                  {payment.externalId}
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>External Payment Token</TableCell>
              <TableCell>{payment.externalToken}</TableCell>
            </TableRow>
          </>
        ) : (
          <TableRow>
            <TableCell colSpan={2}>No payment.</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
