import { Button, Checkbox, Container, FormControlLabel, FormGroup, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { useState } from "react";
import { useSessionContext } from "../contexts/SessionContext";

export default function ConsentPage() {
  const { t } = useTranslation();
  const { consent } = useSessionContext();

  const [checkedPrivacy, setCheckedPrivacy] = useState<boolean>(false);
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);

  const handleChangePolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedPrivacy(event.target.checked);
  };

  const handleChangeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTerms(event.target.checked);
  };

  const onContinue = () => {
    consent();
  };

  const check1 = parse(t("consent.check-privacy-policy"));
  const check2 = parse(t("consent.check-terms-and-conditions"));

  return (
    <Container sx={{ marginTop: 5 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          {t("consent.subtitle")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("consent.text")}
        </Typography>

        <FormGroup sx={{ marginTop: 3, marginBottom: 3 }}>
          <FormControlLabel control={<Checkbox checked={checkedPrivacy} onChange={handleChangePolicy} />} label={check1} />
          <FormControlLabel control={<Checkbox checked={checkedTerms} onChange={handleChangeTerms} />} label={check2} />
        </FormGroup>

        <Button variant="contained" disabled={!checkedPrivacy || !checkedTerms} onClick={onContinue}>
          {t("consent.continue")}
        </Button>
      </Paper>
    </Container>
  );
}
