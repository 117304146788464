import { Confidence } from "../ImageAnalysis/Confidence";
import { Notify } from "../Notify";
import Consult from "./Consult";
import ConsultPrescription from "./ConsultPrescription";
import { ConsultPrescriptionState } from "./ConsultPrescriptionState";
import consultService from "./ConsultService";
import { ConsultState } from "./ConsultState";
import CreateConsultRequest from "./Dto/CreateConsultRequest";

export default class ConsultHelpers {
  public static isEditable(consult?: Consult): boolean {
    if (!consult) return false;
    return consult.state === ConsultState.Processing;
  }

  public static async createConsult(
    organizationId: string,
    planId: string,
    paymentMethodId: string | undefined,
    language: string,
    consultTypeId: string | undefined,
    confidence: Confidence | undefined,
    conditions: string[],
    data: any,
    promoCode?: string
  ): Promise<Consult | undefined> {
    let createConsultRequest: CreateConsultRequest = {
      language: language,
      consultPlanId: planId,
      consultTypeId: consultTypeId,
      confidence: confidence,
      conditions: conditions,
      data: data,
      paymentMethodId: paymentMethodId,
      promoCode: promoCode,
    };
    const createResponse = await consultService.createConsult(organizationId, createConsultRequest);

    if (!createResponse || !createResponse.consultId) {
      return undefined;
    }
    if (createResponse.errorMessage) {
      Notify.error(createResponse.errorMessage);
      return undefined;
    }

    const consult = consultService.getConsult(organizationId, createResponse.consultId);
    return consult;
  }

  public static isConsultActive(consult: Consult): boolean {
    return consult.state !== ConsultState.Cancelled && consult.state !== ConsultState.Closed;
  }

  public static getVisiblePrescriptions(isPractitioner: boolean, prescriptions: ConsultPrescription[]) {
    if (isPractitioner) return prescriptions;

    return prescriptions.filter((x) => x.state === ConsultPrescriptionState.Approved || x.state === ConsultPrescriptionState.Submitted);
  }

  public static getConsultDataCondition(consult: Consult): string | undefined {
    let condition = consult.data["Condition"];
    if (condition && (condition as string)) return condition as string;
    return undefined;
  }

  public static getConsultDataImageList(consult: Consult): string[] | undefined {
    let imageList = consult.data["ImageList"];
    if (imageList && (imageList as string)) return imageList.split(",");
    return undefined;
  }
}
