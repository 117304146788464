import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConsultPrescriptionState } from "../../services/Consults/ConsultPrescriptionState";

export default function ConsultPrescriptionStateComponent({ state }: { state: ConsultPrescriptionState }) {
  const { t } = useTranslation();

  const color =
    state === ConsultPrescriptionState.New
      ? "primary"
      : state === ConsultPrescriptionState.Submitted
      ? "info"
      : state === ConsultPrescriptionState.Approved
      ? "success"
      : state === ConsultPrescriptionState.Cancelled
      ? "error"
      : "primary";

  const variant = state === ConsultPrescriptionState.New || state === ConsultPrescriptionState.Cancelled ? "outlined" : "filled";

  return <Chip label={t("consult.prescription-status-" + state)} color={color} variant={variant} />;
}
