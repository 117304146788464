import { Button } from "@mui/material";
import { ChangeEvent, useRef } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import documentHelper, { DocumentFormatType } from "../../services/Documents/DocumentHelper";

export default function UploadFileButton({ onFileSelected, disabled }: { onFileSelected: (files: FileList) => void; disabled: boolean }) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.files);
    if (!e.target.files || e.target.files.length < 1) return;
    onFileSelected(e.target.files);
  };

  return (
    <Button size="large" variant="contained" sx={buttonStyle} onClick={() => inputRef.current?.click()} disabled={disabled}>
      <input type="file" ref={inputRef} onChange={handleFileChange} accept={documentHelper.getFormats(DocumentFormatType.All).join(",")} hidden />
      <AttachFileIcon />
    </Button>
  );
}

const buttonStyle = { height: "100%", marginRight: 1 };
