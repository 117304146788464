class LoggingService {
  log() {
    if (process.env.REACT_APP_LOG === "1") console.log(arguments);
  }

  logError(componentName, actionStr, object) {
    log("INFO", componentName, actionStr, object);
  }

  logInfo(componentName, actionStr, object) {
    if (process.env.REACT_APP_LOG === "1") {
      log("INFO", componentName, actionStr, object);
    }
  }

  logTrace(componentName, actionStr, object) {
    if (process.env.REACT_APP_LOG === "1") {
      log("TRACE", componentName, actionStr, object);
    }
  }
}

const logger = new LoggingService();
export default logger;

function log(logType, componentName, actionStr, object) {
  let messageStr = `(${logType}) ${componentName} [${actionStr}]`;

  if (object) {
    console.log(`${messageStr} => `, object);
  } else {
    console.log(messageStr);
  }
}
