import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import i18n from "../../i18n";
import DashboardCard from "../../components/DashboardCard";
import FormatHelpers from "../../services/FormatHelper";
import { useCreateConsultContext } from "./CreateConsultContext";

export default function SelectPlan() {
  const { t } = useTranslation();
  const { organization, setPlan } = useCreateConsultContext();

  const title = organization?.organization.createConsultPage.title ? organization.organization.createConsultPage.title[i18n.language] : t("create-consult.title");
  const subTitle = organization?.organization.createConsultPage.subTitle ? organization.organization.createConsultPage.subTitle[i18n.language] : t("create-consult.subtitle");
  const headingText = organization?.organization.createConsultPage.headingText ? organization.organization.createConsultPage.headingText[i18n.language] : undefined;

  return (
    <Container sx={{ marginTop: 5 }}>
      <Typography variant="h1" mb={1} textAlign="center">
        {parse(title)}
      </Typography>
      <Typography color="text.secondary" mb={4} textAlign="center">
        {parse(subTitle)}
      </Typography>
      {headingText && (
        <Typography color="text.secondary" mb={4} textAlign="center">
          {parse(headingText)}
        </Typography>
      )}

      <Stack marginLeft={{ md: 5, lg: 25 }} marginRight={{ md: 5, lg: 25 }}>
        {organization!.plans.map((plan) => {
          const title = plan.title[i18n.language] ?? "";
          const description = plan.description[i18n.language] ?? "";
          const text = plan.text[i18n.language] ?? null;

          return (
            <Box key={plan.id} mb={4}>
              <DashboardCard title={title}>
                <Box mt={1} mb={2}>
                  {parse(description)}
                </Box>
                {text && <Box mb={1}>{text}</Box>}
                <Box mb={2}>
                  <Stack direction="row">
                    <Typography fontSize="1.1rem">{t("common.price")}:</Typography>
                    <Typography ml={1} fontWeight="bold" fontSize="1.1rem">
                      {FormatHelpers.formatMoney(plan.price, i18n.language)}
                    </Typography>
                  </Stack>
                </Box>

                <Button variant="contained" onClick={() => setPlan(plan)}>
                  {t("create-consult.start-consult")}
                </Button>
              </DashboardCard>
            </Box>
          );
        })}
      </Stack>
    </Container>
  );
}
