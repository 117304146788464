import FormatHelpers from "../FormatHelper";
import Field from "./Field";
import { FieldType } from "./FieldType";

export default class FieldHelper {
  public static getValue(field: Field, data: any, language: string) {
    let value = "";
    if (data.hasOwnProperty(field.id)) {
      value = data[field.id];

      //console.log("     ", value);

      if (value.length > 0) {
        if (field.type === FieldType.YesNo) {
          value = value === "true" ? "Yes" : "No";
        } else if (field.type === FieldType.Date) {
          value = FormatHelpers.formatDateShort(new Date(value));
        } else if (field.type === FieldType.Number) {
          value = parseInt(value).toString();

          const fieldidUnit = field.id + "Unit";
          if (data.hasOwnProperty(fieldidUnit)) {
            value += " " + data[fieldidUnit];
          }
        } else if (field.type === FieldType.ImageList) {
          return value as string;
        }
      }
    }

    if (field.type === FieldType.ImageList) {
      if (value.length === 0) {
        return "";
      }
      let ids = value.split(",");
    }

    if (field.type === FieldType.YesNo && value.length === 0) {
      //console.log("     Yes/No", field.id);
      value = "No";
    }

    if (field.type === FieldType.Text) {
      const conditionnal = field.properties["conditionnal"];
      //console.log("     text", conditionnal, value);
      if (conditionnal && value.length === 0) {
        value = "No";
      }
    }
    if (field.values.length > 0) {
      const fv = field.values.find((fv) => fv.id === value);
      if (fv) {
        value = fv.label[language];
      }
    }

    return value;
  }
}
