import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import Document from "./Document";
import documentService from "./DocumentService";
import errorHelper from "../Core/ErrorHelper";
import { useTranslation } from "react-i18next";
import logger from "../Logger";

export interface IUploadDocument {
  documents: Document[];
  addFiles: (e: ChangeEvent<HTMLInputElement>) => void;
  removeDocument: (id: string) => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  loading: boolean;
}

export type UploadDocumentProps = {
  organizationId?: string;
  defaultDocuments?: Array<string>;
  onChange?: (documents: Array<Document>) => void;
};

export default function useUploadDocuments({ organizationId, defaultDocuments, onChange }: UploadDocumentProps): IUploadDocument {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (organizationId && defaultDocuments && defaultDocuments.length > 0) {
      setLoading(true);
      documentService
        .getDocumentsFromIds(organizationId, defaultDocuments)
        .then((data) => setDocuments(data))
        .catch((e) => errorHelper.handleError(t, e))
        .finally(() => setLoading(false));
    }
  }, [organizationId, defaultDocuments]);

  const addFiles = (e: ChangeEvent<HTMLInputElement>) => {
    logger.logInfo("useUploadDocuments", "addFiles", e.target.files);

    if (!e.target.files || e.target.files.length < 1) return;

    if (organizationId) {
      setLoading(true);
      documentService
        .uploadDocument(organizationId, e.target.files)
        .then((data) => {
          if (data) {
            logger.logInfo("useUploadDocuments", "addFiles -> uploadDocument", data);
            setDocuments((prev) => {
              let newDocuments = [...prev, ...data] as Document[];
              callBack(newDocuments);
              return newDocuments;
            });
          }
        })
        .catch((e) => errorHelper.handleError(t, e))
        .finally(() => setLoading(false));
    }
  };

  const removeDocument = (documentId: string) => {
    logger.logTrace("useUploadDocuments", documentId);

    let document = documents.find((x) => x && x.id === documentId);
    if (document) {
      setLoading(true);
      documentService
        .deleteDocument(document.organizationId, documentId)
        .then((data) => {
          if (data) {
            setDocuments((prev) => {
              let newDocuments = prev.map((x) => (x && x.id === documentId ? { ...x, deleted: true } : x));
              callBack(newDocuments);
              return newDocuments;
            });
          }
        })
        .catch((e) => errorHelper.handleError(t, e))
        .finally(() => setLoading(false));
    }
  };

  const callBack = (newDocuments: Document[]) => {
    logger.logInfo("useUploadDocuments", "callback", newDocuments);
    if (onChange) {
      onChange(newDocuments);
    }
  };

  return { documents, inputRef, addFiles, removeDocument, loading };
}
