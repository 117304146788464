import { Place } from "./Pharmacy";

class PharmacyHelper {
  getFullAddress(place: Place): string {
    return `${place.address.streetAddress}, ${place.address.place}, ${place.address.regionCode} ${place.address.postalCode}`;
  }

  getCountryRegionString(place: Place) {
    return `${place.address.regionCode}, ${place.address.country}`;
  }

  getFaxNumber(place: Place) {
    return place.phones.find((x) => x.type === "FAX");
  }
}

const pharmacyHelper = new PharmacyHelper();
export default pharmacyHelper;
