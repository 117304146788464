import { useState } from "react";
import FileUpload from "./Image/FileUpload";
import FileData from "./Image/FileData";
import ImageAnalysisResult from "../services/ImageAnalysis/ImageAnalysisResult";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import imageAnalysisService from "../services/ImageAnalysis/ImageAnalysisService";
import parse from "html-react-parser";

function UploadZone({ handleFileUpload }: { handleFileUpload: (files: Array<FileData>) => void }) {
  const { t } = useTranslation();

  return (
    <Stack display="flex" flexDirection="column" alignItems="center">
      <FileUpload
        onChange={handleFileUpload}
        title={t("image-analyzer.upload-images-text")}
        subtitle={t("image-analyzer.upload-images-text-details")}
        actionText={t("image-analyzer.launch-analysis")}
        progressText={t("image-analyzer.analysis-running")}
        minImages={2}
      />
    </Stack>
  );
}

enum AnalyzeState {
  UploadPictures,
  AnalyzeInProgress,
  AnalyzeError,
  AnalyzeDone,
}

interface ImageAnalyzerProps {
  onAnalysisDone: (result: ImageAnalysisResult, files: Array<FileData>) => void;
  onCancel: () => void;
}
export default function ImageAnalyzer(props: ImageAnalyzerProps) {
  const { t } = useTranslation();

  const [analyzeState, setAnalyzeState] = useState<AnalyzeState>(AnalyzeState.UploadPictures);

  const handleFileUpload = async (files: Array<FileData>) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; ++i) {
        const file = files[i];
        if (file.valid) {
          formData.append("file", file.file);
        }
      }

      setAnalyzeState(AnalyzeState.AnalyzeInProgress);

      const response = await imageAnalysisService.analzyeImages(formData);
      // console.log(response);
      if (response) {
        setAnalyzeState(AnalyzeState.AnalyzeDone);
        props.onAnalysisDone(response, files);
      }
    } catch (error) {
      console.error(error);
      setAnalyzeState(AnalyzeState.AnalyzeError);
    }
  };

  const restartUpload = () => {
    setAnalyzeState(AnalyzeState.UploadPictures);
  };

  return (
    <>
      {(analyzeState === AnalyzeState.UploadPictures || analyzeState === AnalyzeState.AnalyzeInProgress) && <UploadZone handleFileUpload={handleFileUpload} />}

      {analyzeState === AnalyzeState.AnalyzeError && (
        <Box>
          <Typography>{parse(t("image-analyzer.analysis-error"))}</Typography>
          <Stack mt={2} spacing={1} direction="row">
            <Button variant="contained" onClick={restartUpload}>
              {t("image-analyzer.analysis-error-reupload")}
            </Button>
            <Button variant="contained" onClick={props.onCancel}>
              {t("image-analyzer.analysis-error-chat")}
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}
