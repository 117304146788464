import Me from "./Me";
import UserRoles from "./UserRoles";

export default class MeHelpers {
  public static isPractitioner(me?: Me): boolean {
    if (!me) return false;
    return me && me.roles.includes(UserRoles.Practitioner);
  }

  public static isOrgAdmin(me?: Me): boolean {
    if (!me) return false;
    return me && (me.roles.includes(UserRoles.OrgAdmin) || me.roles.includes(UserRoles.PlatformAdmin));
  }

  public static isPlatformAdmin(me?: Me): boolean {
    if (!me) return false;
    return me && me.roles.includes(UserRoles.PlatformAdmin);
  }

  public static isPractitionerOrAdmin(me?: Me): boolean {
    if (!me) return false;
    return me && (this.isPractitioner(me) || this.isOrgAdmin(me));
  }
}
