import { AccountInfo, EventType, RedirectRequest } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import msalInstance from "./msal-instance";
import i18n from "../../i18n";

class MsalService {
  public async initialize(callback: any) {
    await msalInstance.initialize();

    msalInstance.addEventCallback((event) => {
      // console.log("msalInstance.eventCallback", event);

      if (
        // (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload
      ) {
        const payload = event.payload as any;
        if (payload.account) {
          // console.log("MsalService: setActiveAccount", payload.account);
          msalInstance.setActiveAccount(payload.account);
          if (callback) callback();
        }
      }
    });

    if (callback) callback();
  }

  public async getToken(): Promise<string | undefined> {
    const account = this.getAccount();
    if (!account) {
      return undefined;
    }

    const request: any = {
      ...loginRequest,
      account: account,
    };
    try {
      const response = await msalInstance.acquireTokenSilent(request);
      if (response?.accessToken) return response.accessToken;
      return response.idToken;
    } catch (error) {
      console.error(`Error while acquiring token: ${error}`);
      msalInstance.acquireTokenRedirect(request);
      return undefined;
    }
  }

  public getAccount(): AccountInfo | null {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      return accounts[0];
    }
    return null;
  }

  public async login() {
    let request: RedirectRequest = {
      ...loginRequest,
      extraQueryParameters: { ui_locales: `${i18n.language}-ca` },
      prompt: "login",
    };
    // await msalInstance.loginRedirect(request).catch((e) => {
    //   console.error(e);
    // });
    await msalInstance.loginPopup(request).catch((e) => {
      console.error(e);
    });
  }

  public async signup() {
    let request: RedirectRequest = {
      ...loginRequest,
      extraQueryParameters: { ui_locales: `${i18n.language}-ca` },
      prompt: "create",
    };
    await msalInstance.loginRedirect(request).catch((e) => {
      console.error(e);
    });
  }

  public async logout(redirectUrl: string): Promise<void> {
    await msalInstance.logoutRedirect({
      postLogoutRedirectUri: redirectUrl,
    });
  }
}

const msalService = new MsalService();
export default msalService;
