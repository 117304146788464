import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FaxStateComponent({ resultCode }: { resultCode?: string }) {
  const { t } = useTranslation();

  const [color, setColor] = useState<"success" | "info" | "error">();
  const [textKey, setTextKey] = useState<string>("0");

  useEffect(() => {
    if (resultCode) {
      if (resultCode === "0") {
        setColor("success");
        setTextKey("success");
      } else if (resultCode === "1000") {
        setColor("info");
        setTextKey("pending");
      } else {
        setColor("error");
        setTextKey("error");
      }
    }
  }, [resultCode]);

  return <Chip label={t(`consult.prescription-fax-status-${textKey}`)} color={color} variant="outlined" />;
}
