import { AppBar, Avatar, Box, Button, Container, IconButton, Link, ListItemIcon, Menu, MenuItem, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChangeLanguageButton from "./ChangeLanguageButton";
import { AuthenticatedTemplate } from "@azure/msal-react";
import authService from "../services/Authentification/AuthService";
import { useSessionContext } from "../contexts/SessionContext";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Logout } from "@mui/icons-material";
import MeHelpers from "../services/Authentification/MeHelpers";

export default function AppBarcomponent() {
  const { t } = useTranslation();
  const { user, organization } = useSessionContext();
  const navigate = useNavigate();

  const [homeUrl, setHomeUrl] = useState<string>("");
  const [accountAnchor, setAccountAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(accountAnchor);

  useEffect(() => {
    let organizationId = organization?.organization.id ?? "dermsmart";
    setHomeUrl(`/organizations/${organizationId}`);
  }, [organization]);

  const handleLogoutRedirect = () => {
    authService.logout(homeUrl);
  };

  // console.log("org", organization, homeUrl);

  const handleAccountClick = (event: React.MouseEvent<HTMLElement>) => {
    setAccountAnchor(event.currentTarget);
  };
  const handleAccountClose = () => {
    setAccountAnchor(null);
  };

  const isOrgAdmin = MeHelpers.isOrgAdmin(user);
  const archiveEnable = isOrgAdmin && (organization?.organization.id === "dermago" || organization?.organization.id === "tontest");

  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff", color: "black" }}>
      <Container sx={{ xs: { padding: 0, paddingLeft: 1, paddingRight: 1 }, lg: { padding: 0 } }}>
        <Toolbar disableGutters>
          <Link component={RouterLink} to={homeUrl} underline="none" variant="h6" height={{ xs: "40px", lg: "60px" }} p={{ xs: 0, lg: "5px" }}>
            {organization?.organization.useOrganizationBranding ? (
              <img src={organization.organization.logoUrl} alt={organization.organization.name} height="100%" />
            ) : (
              <img src="/images/dermsmart.png" alt="Dermsmart" height="100%" />
            )}
          </Link>

          <Box sx={{ flexGrow: 1 }}>
            {isOrgAdmin && (
              <Link component={RouterLink} to={"/admin/stats"} underline="none" ml={3}>
                {t("layout.admin-page")}
              </Link>
            )}

            {archiveEnable && (
              <Link component={RouterLink} to={`/organizations/${organization.organization.id}/admin/archives`} underline="none" ml={3}>
                Archives
              </Link>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <ChangeLanguageButton />

            <AuthenticatedTemplate>
              {user && (
                <>
                  <IconButton
                    onClick={handleAccountClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }} />
                  </IconButton>
                  <Menu
                    anchorEl={accountAnchor}
                    id="account-menu"
                    open={open}
                    onClose={handleAccountClose}
                    onClick={handleAccountClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      {t("profile.title")}
                    </MenuItem>
                    <MenuItem onClick={handleLogoutRedirect}>{t("auth.logout")}</MenuItem>
                  </Menu>
                </>
              )}
            </AuthenticatedTemplate>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
