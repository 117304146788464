import { Container, Grid, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useSessionContext } from "../contexts/SessionContext";
import { useEffect, useState } from "react";
import userService from "../services/Authentification/UserService";
import { Notify } from "../services/Notify";
import OrgAdminStats from "../services/Authentification/OrgAdminStats";
import { useTranslation } from "react-i18next";
import { LineChart, lineElementClasses, markElementClasses } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import React from "react";
import DashboardCard from "../components/DashboardCard";

export default function OrgAdminStatsPage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const [stats, setStats] = useState<OrgAdminStats | undefined>(undefined);

  useEffect(() => {
    async function get() {
      if (user) {
        try {
          const stats = await userService.getMeDashboardStats();
          setStats(stats);
        } catch (e) {
          Notify.error(`${t("common.unexpected-error")}`);
        }
      }
    }

    get();
  }, [user]);

  if (!user || !stats) return <></>;

  const years = stats.years;
  const months = stats.months;
  const practitionersCurrentMonth = stats.practitionersCurrentMonth;
  const practitionersLastMonth = stats.practitionersLastMonth;

  return (
    <Container sx={{ marginTop: 5 }}>
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <DashboardCard title={t("adminStats.year")}>
            <LineChart
              slotProps={{ legend: { hidden: true } }}
              height={400}
              grid={{ horizontal: true }}
              series={[
                { curve: "linear", data: years.valueData[0].values, label: years.valueData[0].name, id: "d0", showMark: false },
                { curve: "linear", data: years.valueData[1].values, label: years.valueData[1].name, id: "d1", showMark: false },
              ]}
              xAxis={[{ scaleType: "point", data: years.axisData }]}
              yAxis={[
                {
                  label: t("adminStats.consults"),
                },
              ]}
              sx={{
                [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
                  strokeWidth: 1,
                },
                ".MuiLineElement-series-d0": {
                  strokeDasharray: "3 3 3 3",
                },
                [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
                  fill: "#fff",
                },
                [`& .${markElementClasses.highlighted}`]: {
                  stroke: "none",
                },
              }}
            />
          </DashboardCard>
        </Grid>

        <Grid item lg={6}>
          <DashboardCard title={t("adminStats.last60days")}>
            <LineChart
              slotProps={{ legend: { hidden: true } }}
              height={400}
              grid={{ horizontal: true }}
              series={[{ curve: "linear", data: months.valueData[0].values, label: months.valueData[0].name, id: "d0", showMark: false }]}
              xAxis={[{ scaleType: "point", data: months.axisData }]}
              yAxis={[
                {
                  label: t("adminStats.consults"),
                },
              ]}
              sx={{
                [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
                  strokeWidth: 1,
                },
                [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
                  fill: "#fff",
                },
                [`& .${markElementClasses.highlighted}`]: {
                  stroke: "none",
                },
              }}
            />
          </DashboardCard>
        </Grid>

        <Grid item lg={6}>
          <DashboardCard title={t("adminStats.practitionerCurrentMonth")}>
            <Table>
              <TableBody>
                {practitionersCurrentMonth.map((kv, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>{kv.key}</TableCell>
                        <TableCell>{kv.value}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </DashboardCard>
        </Grid>

        {practitionersLastMonth.length > 0 && (
          <Grid item lg={6}>
            <DashboardCard title={t("adminStats.practitionerLastMonth")}>
              <Table>
                <TableBody>
                  {practitionersLastMonth.map((kv, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>{kv.key}</TableCell>
                          <TableCell>{kv.value}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </DashboardCard>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
