import BaseApiClient from "../BaseApiClient";
import PharmacyResponse from "./Pharmacy";

class PharmacyService extends BaseApiClient {
  async searchPharmacy(query?: string, latitude?: number, longitude?: number): Promise<PharmacyResponse | undefined> {
    if (!query && !latitude && !longitude) return undefined;

    let aroundLatLng: string | undefined = undefined;
    if (latitude && longitude) {
      aroundLatLng = `${latitude}, ${longitude}`;
    }

    const response = await this.post<any, PharmacyResponse | undefined>(`${this.apiUrl}/Pharmacies`, {
      Query: query,
      // AroundLatLng: "45.54303882614218, -73.91882052704435",
      AroundLatLng: aroundLatLng,
    });
    if (!response) return undefined;
    return response;
  }
}

const pharmacyService = new PharmacyService();
export default pharmacyService;
