import { useEffect, useState } from "react";
import { Box, ButtonBase, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

/* Icons */
import FolderOffIcon from "@mui/icons-material/FolderOff";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";

import Document from "../../services/Documents/Document";
import documentHelper from "../../services/Documents/DocumentHelper";
import documentService from "../../services/Documents/DocumentService";
import Loading from "../Loading";

interface DocumentListProps {
  documents: Array<Document>;

  onUploadClick?: () => void;
  onRemove?: (documentId: string) => void;

  allowUpload?: boolean;
  showDeleted?: boolean;
}

export default function DocumentList(props: DocumentListProps) {
  const { t } = useTranslation();
  const { documents, onUploadClick, onRemove, allowUpload, showDeleted } = props;

  return (
    <Stack direction="row" spacing={1} padding={1}>
      {documents.map((document, index) => {
        let showDocument = !document.deleted || (document.deleted && showDeleted);
        const src = documentService.getDocumentSource(document.organizationId, document.id);

        return (
          <div key={index}>
            {document && showDocument && (
              <Box position="relative" height={100}>
                <DocumentIcon document={document} />
                {onRemove && !document.deleted && (
                  <IconButton size="small" onClick={() => onRemove(document.id)} sx={IconButtonStyle}>
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                  </IconButton>
                )}
              </Box>
            )}
          </div>
        );
      })}

      {allowUpload && (
        <Stack width={100} height={100} justifyContent="center" alignItems="center" border="dashed" borderColor="divider" sx={{ borderWidth: 2 }}>
          <ButtonBase onClick={onUploadClick}>
            <Stack alignItems="center">
              <AddIcon />
              <Typography mt={1}>{t("image-analyzer.upload")}</Typography>
            </Stack>
          </ButtonBase>
        </Stack>
      )}
    </Stack>
  );
}

enum DocumentIconType {
  Document,
  Image,
}

function DocumentIcon({ document }: { document: Document }) {
  const [documentType, setDocumentType] = useState<DocumentIconType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // logger.logTrace("ImageList", "DocumentIcon", document.filename);

    if (!document.deleted) {
      if (documentHelper.isImage(document)) setDocumentType(DocumentIconType.Image);
      else setDocumentType(DocumentIconType.Document);
    } else {
      setLoading(false);
    }
  }, [document]);

  useEffect(() => {
    if (documentType) {
      setLoading(false);
    }
  }, [documentType]);

  if (loading) {
    return <Loading center />;
  }

  if (document.deleted) {
    return (
      <Box sx={DocIconBoxStyle}>
        <FolderOffIcon fontSize="large" />
      </Box>
    );
  }

  return (
    <>
      {documentType === DocumentIconType.Image ? (
        <img src={documentService.getDocumentSource(document.organizationId, document.id)} height={100} alt={document.filename} />
      ) : (
        <Box sx={DocIconBoxStyle}>
          <DescriptionIcon fontSize="large" />
        </Box>
      )}
    </>
  );
}

const IconButtonStyle = {
  position: "absolute",
  top: 2,
  right: 2,
  borderWidth: "2px",
  borderColor: "#666",
  padding: "1px",
  backgroundColor: "#9999",
  opacity: "100",
};

const DocIconBoxStyle = {
  width: 100,
  height: "auto",
  textAlign: "center",
  border: "1px solid #9999",
};
