import { useTranslation } from "react-i18next";
import { Notify } from "../Notify";

class ErrorHelper {
  handleError(t: any, e?: any) {
    Notify.error(`${t("common.unexpected-error")}`);
    console.error(e);
  }
}

const errorHelper = new ErrorHelper();
export default errorHelper;
