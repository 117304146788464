import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageAnalyzer from "../../components/ImageAnalyzer";
import ImageAnalysisResult from "../../services/ImageAnalysis/ImageAnalysisResult";
import FileData from "../../components/Image/FileData";
import ImageList from "../../components/Image/ImageList";
import i18n from "../../i18n";
import DashboardCard from "../../components/DashboardCard";
import ResultDetail from "../../services/ImageAnalysis/ResultDetail";

interface Props {
  setConsultCondition: (consultConditionId: string, documentIds: string[]) => Promise<void>;
  cancel: () => void;
}
export default function IdentifyCondition(props: Props) {
  const { t } = useTranslation();

  const [result, setResult] = useState<ImageAnalysisResult | undefined>(undefined);
  const [files, setFiles] = useState<Array<FileData>>([]);

  const onAnalysisDone = (result: ImageAnalysisResult, files: Array<FileData>) => {
    setFiles(files);
    setResult(result);
  };

  return (
    <Container sx={{ marginTop: 5 }}>
      <Typography variant="h1" gutterBottom>
        {t("identify-condition.title")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Paper sx={{ padding: 3 }}>
            {!result && <ImageAnalyzer onAnalysisDone={onAnalysisDone} onCancel={props.cancel} />}

            {result && (
              <Grid container>
                <Grid item lg={12}>
                  {files.length > 0 && <ImageList files={files} />}

                  {result.resultDetails.length <= 0 && <Typography mt={1}>{t("identify-condition.analysis-result-nodisease")}</Typography>}
                  {result.resultDetails.length > 0 && (
                    <>
                      <Typography mt={1}>{t("identify-condition.analysis-result-msg")}</Typography>
                      <ul>
                        {result.resultDetails
                          .filter((result) => result.condition)
                          .map((detail, index) => {
                            return <li key={index}>{detail.label[i18n.language]}</li>;
                          })}
                      </ul>
                    </>
                  )}

                  <Typography mt={4} mb={2}>
                    {t("identify-condition.analysis-result-advices")}
                  </Typography>
                  <Grid container spacing={3}>
                    {result.resultDetails
                      .filter((result) => result.consult)
                      .map((resultDetail: ResultDetail) => {
                        const ct = resultDetail.consult;
                        const label = ct?.label[i18n.language] ?? "";
                        const description = ct?.description[i18n.language] ?? "";

                        if (ct?.id) {
                          return (
                            <Grid key={ct?.id} item xs={12} md={6} lg={4}>
                              <DashboardCard title={label}>
                                <Box mb={3} minHeight={80}>
                                  {description}
                                </Box>

                                <Button onClick={() => props.setConsultCondition(ct.id, result.documentIds)}>{t("create-consult.start-consult")}</Button>
                              </DashboardCard>
                            </Grid>
                          );
                        } else return <></>;
                      })}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
