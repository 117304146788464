import { useState } from "react";
import Coordinate from "./Coordinates";
import { useTranslation } from "react-i18next";
import { Notify } from "../Notify";

export default function useCoordinate() {
  const { t } = useTranslation();

  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();

  const error = () => {
    Notify.error(t("consult.error-retrive-geolocation"));
  };

  const fetch = (callBack?: (position: GeolocationPosition) => void) => {
    const success = (position: GeolocationPosition) => {
      setCoordinate({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      if (callBack) callBack(position);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      Notify.info(t("consult.geolocation-error"));
    }
  };

  return { coordinate, fetch };
}
