export default class FormatHelpers {
  public static formatMoney(value: any, locale: string) {
    if (value && typeof value === "number") {
      const str = value.toLocaleString(locale, { minimumFractionDigits: Number.isInteger(value) ? 0 : 2 });
      return locale === "fr" ? `${str} $` : `$${str}`;
    }
    return "$0.00";
  }

  public static formatDateTimeShort(value: any) {
    if (!value) return "";
    return `${value.getFullYear()}/${(value.getMonth() + 1).toString().padStart(2, "0")}/${value.getDate().toString().padStart(2, "0")} ${value
      .getHours()
      .toString()
      .padStart(2, "0")}:${value.getMinutes().toString().padStart(2, "0")}`;
  }

  public static formatDateShort(value: any) {
    if (!value) return "";
    return `${value.getFullYear()}/${(value.getMonth() + 1).toString().padStart(2, "0")}/${value.getDate().toString().padStart(2, "0")}`;
  }
}
