import { useRef } from "react";
import useDocument from "../../services/Documents/useDocument";
import { Button, Stack, Typography } from "@mui/material";
import documentService from "../../services/Documents/DocumentService";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

export default function DocumentButton({ documentId, organizationId }: { documentId?: string; organizationId?: string }) {
  const inputRef = useRef<HTMLAnchorElement | null>(null);
  const { document, loading } = useDocument(organizationId, documentId);

  if (loading || !document) {
    return <></>;
  }

  return (
    <Button title={document.filename} onClick={() => inputRef.current?.click()} disabled={document.deleted} variant="outlined" size="small">
      {!document.deleted && <a type="file" ref={inputRef} href={documentService.getDocumentSource(document.organizationId, document.id)} target="_blank" download hidden />}

      <Stack direction="row" alignItems="center" overflow="hidden">
        <FileDownloadOutlinedIcon />

        <Typography variant="body2" sx={TextStyle}>
          {document.filename}
        </Typography>
      </Stack>
    </Button>
  );
}

const TextStyle = {
  fontSize: "0.75rem",
  marginLeft: "7px",
  maxWidth: "100px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  display: "inlineBlock",
};
