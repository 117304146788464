import { TextField, Typography } from "@mui/material";
import i18n from "../../i18n";
import PropsDynamicField from "./PropsDynamicField";

export default function DynamicFieldLabel(props: PropsDynamicField) {
  const { field } = props;

  return (
    <>
      <Typography mb={1} minHeight="24px"></Typography>
      <TextField fullWidth value={field.label[i18n.language]} disabled />
    </>
  );
}
