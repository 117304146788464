export default class TimeSpan {
  private totalMilliseconds: number;
  constructor(milliseconds: number) {
    this.totalMilliseconds = milliseconds;
  }

  public getTotalHours() {
    const totalSeconds = this.totalMilliseconds / 1000;

    // Total number of minutes in the difference
    const totalMinutes = totalSeconds / 60;

    // Total number of hours in the difference
    return totalMinutes / 60;
  }

  public getDays() {
    const totalHours = this.getTotalHours();

    return totalHours / 24;
  }
}
