import { createContext, useContext, useEffect, useState } from "react";
import consultService from "../../services/Consults/ConsultService";
import { Notify } from "../../services/Notify";
import { useTranslation } from "react-i18next";
import ConsultPlan from "../../services/ConsultPlans/ConsultPlan";
import consultPlanService from "../../services/ConsultPlans/ConsultPlanService";
import ConsultType from "../../services/Consults/ConsultType";
import consultTypeService from "../../services/Consults/ConsultTypeService";
import ConsultExtended from "../../services/Consults/ConsultExtended";
import { useSessionContext } from "../../contexts/SessionContext";

export interface IConsultContext {
  loading: boolean;
  refresh: () => Promise<void>;

  consult: ConsultExtended | undefined;

  consultType: ConsultType | undefined;
  plan: ConsultPlan | undefined;
}
const defaultState = {
  loading: false,
  refresh: async () => {},

  consult: undefined,

  consultType: undefined,
  plan: undefined,
};

const ConsultContext = createContext<IConsultContext>(defaultState);

export const useConsultContext = () => useContext(ConsultContext);

type Props = {
  organizationId: string | undefined;
  consultId: string | undefined;
  children?: JSX.Element;
};

function ConsultProvider({ organizationId, consultId, children }: Props) {
  const { t } = useTranslation();

  const { user } = useSessionContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [consult, setConsult] = useState<ConsultExtended | undefined>(undefined);
  const [plan, setPlan] = useState<ConsultPlan | undefined>(undefined);
  const [consultType, setConsultType] = useState<ConsultType | undefined>(undefined);

  useEffect(() => {
    refresh();
  }, [organizationId, user]);

  async function refresh() {
    if (organizationId && consultId && user) {
      // console.log("useConsultContext.refresh", organizationId, consultId);

      try {
        setLoading(true);

        const consult = await consultService.getConsultExtended(organizationId, consultId);
        setConsult(consult);
        if (consult) {
          if (consult.consultTypeId) {
            const consultType = await consultTypeService.getConsultType(organizationId, consult.consultTypeId);
            setConsultType(consultType);

            const consultPlan = await consultPlanService.getConsultPlan(organizationId, consult.consultPlanId, consult.consultTypeId);
            setPlan(consultPlan);
          } else setPlan(undefined);
        }
      } catch (e) {
        Notify.error(`${t("common.unexpected-error")}`);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <ConsultContext.Provider
      value={{
        loading,
        refresh,

        consult,

        consultType,
        plan,
      }}
    >
      {children}
    </ConsultContext.Provider>
  );
}

export { ConsultProvider };
