import { Box, Button, Dialog, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import msalService from "../services/Authentification/msal-service";

interface Props {
  open: boolean;
  visibilityChanged?: (open: boolean) => void;
  returnUrl: string;

  onCancel?: () => Promise<void>;
}
export default function AuthentificationPopup(props: Props) {
  const { t } = useTranslation();

  const close = async () => {
    if (props.onCancel && props.visibilityChanged) {
      props.visibilityChanged(false);
      await props.onCancel();
    }
  };

  const login = () => {
    sessionStorage.setItem("redirect", props.returnUrl);
    msalService.login();
  };

  const signup = () => {
    sessionStorage.setItem("redirect", props.returnUrl);
    msalService.signup();
  };

  return (
    <Dialog
      open={props.open}
      onClose={close}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        component: "form",
      }}
    >
      {/* <DialogTitle>{t("auth-popup.title")}</DialogTitle> */}
      {props.onCancel && (
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>
        <Stack alignContent="center" textAlign="center" mt={7} mb={5} mx={{ xs: 0, lg: 8 }}>
          <Box>
            <AccountCircleIcon color="primary" sx={{ fontSize: 80 }} />
          </Box>
          <Typography mt={2} mb={5}>
            {t("auth-popup.description")}
          </Typography>

          <Button size="large" variant="contained" onClick={login}>
            {t("auth-popup.button")}
          </Button>

          {/* <Button size="large" variant="contained" onClick={login}>
            {t("auth-popup.login-text")}
          </Button>
          <Typography my={4}>{t("auth-popup.or")}</Typography>
          <Button size="large" variant="contained" onClick={signup}>
            {t("auth-popup.signup-text")}
          </Button> */}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
