import React, { useEffect } from "react";
import { Box, Button, Container, Link, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormatHelpers from "../../services/FormatHelper";
import { useSessionContext } from "../../contexts/SessionContext";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link as RouterLink } from "react-router-dom";
import i18n from "../../i18n";
import ConsultStateComponent from "../../components/Consults/ConsultStateComponent";
import Chat from "../Chat/Chat";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import { ConsultMessageType } from "../../services/Consults/ConsultMessageType";
import MeHelpers from "../../services/Authentification/MeHelpers";
import InfoIcon from "@mui/icons-material/Info";
import ConsultDiagnosis from "./ConsultDiagnosis";
import Consult from "../../services/Consults/Consult";
import consultService from "../../services/Consults/ConsultService";
import { Notify } from "../../services/Notify";
import { ConsultState } from "../../services/Consults/ConsultState";
import ConsultPrescriptions from "./ConsultPrescriptions";
import ConsultInformations from "./ConsultInformations";
import { ConsultProvider, useConsultContext } from "./ConsultContext";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import ConsultHelpers from "../../services/Consults/ConsultHelpers";
import AuthentificationPopup from "../../components/AuthentificationPopup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConsultTabAdmin from "./ConsultTabAdmin";
import { PaymentState } from "../../services/Payments/PaymentState";
import PaymentStateComponent from "../../components/Payments/PaymentStateComponent";
import { CreateConsultProvider, useCreateConsultContext } from "../CreateConsult/CreateConsultContext";
import Questionnaire from "../CreateConsult/Questionnaire";
import userService from "../../services/Authentification/UserService";
import errorHelper from "../../services/Core/ErrorHelper";
import ConsultPlan from "../../services/ConsultPlans/ConsultPlan";

function Breadcrumb({ consult }: { consult: Consult }) {
  const { t } = useTranslation();
  const { organizationId } = useParams<string>();

  return (
    <Stack direction="row" mb={2}>
      <Typography>
        <Link component={RouterLink} to={`/organizations/${organizationId}`} underline="none">
          {t("consult.consults")}
        </Link>
      </Typography>

      <Typography>
        <ChevronRightIcon />
      </Typography>

      <Typography fontWeight="bold">#{consult.shortId}</Typography>
    </Stack>
  );
}

function Toolbar({ isPractitioner, isOrgAdmin }: { isPractitioner: boolean; isOrgAdmin: boolean }) {
  const { t } = useTranslation();

  const { consult, refresh } = useConsultContext();

  const startConsult = () => {
    if (consult) {
      consultService
        .startConsult(consult.organizationId, consult.id)
        .then(refresh)
        .catch(() => Notify.error(`${t("common.unexpected-error")}`));
    }
  };

  const closeConsult = () => {
    if (consult) {
      consultService
        .closeConsult(consult.organizationId, consult.id)
        .then(refresh)
        .catch(() => Notify.error(`${t("common.unexpected-error")}`));
    }
  };

  const reopenConsult = () => {
    if (consult) {
      consultService
        .reopenConsult(consult.organizationId, consult.id)
        .then(refresh)
        .catch(() => Notify.error(`${t("common.unexpected-error")}`));
    }
  };

  const cancelConsult = () => {
    if (consult) {
      consultService
        .cancelConsult(consult.organizationId, consult.id)
        .then(refresh)
        .catch(() => Notify.error(`${t("common.unexpected-error")}`));
    }
  };

  const refundConsult = () => {
    if (consult) {
      consultService
        .refundConsult(consult?.organizationId, consult.id)
        .then(refresh)
        .catch(() => Notify.error(`${t("common.unexpected-error")}`));
    }
  };

  if (!consult) return <></>;

  const invoiceUrl = `${process.env.REACT_APP_DERMSMART_API}/organizations/${consult.organizationId}/consults/${consult.id}/invoice` ?? undefined;
  const showInvoice = consult.total > 0 && consult.paymentId && consult.state > 1;

  const showPayment = isOrgAdmin && consult.payment && consult.payment.externalId;

  return (
    <Box my={2}>
      <Stack direction={{ xs: "column", lg: "row" }} flex="1">
        <ConsultStateComponent state={consult.state} />

        <Box flex="1" textAlign={{ xs: "center", lg: "right" }} mt={{ xs: 2, lg: 0 }}>
          {showInvoice && (
            <Button variant="outlined" href={invoiceUrl} target="_blank">
              <ReceiptIcon />
              <Typography ml={1} fontWeight="bold">
                {t("consult.receipt")}
              </Typography>
            </Button>
          )}

          {showPayment && (
            <>
              {consult.payment?.state && consult?.payment?.state === PaymentState.Refunded ? (
                <PaymentStateComponent state={consult.payment?.state} />
              ) : (
                <>
                  {ConsultHelpers.isConsultActive(consult) && (
                    <Button variant="outlined" sx={{ ml: 1 }} onClick={refundConsult}>
                      <Typography fontWeight="bold">{t("consult.refund")}</Typography>
                    </Button>
                  )}
                </>
              )}
            </>
          )}

          {isPractitioner && (
            <>
              {consult.state === ConsultState.New && (
                <Button variant="outlined" sx={{ ml: 1 }} onClick={startConsult}>
                  <Typography fontWeight="bold">{t("consult.start")}</Typography>
                </Button>
              )}

              {consult.state === ConsultState.Processing && (
                <Button variant="outlined" sx={{ ml: 1 }} onClick={closeConsult}>
                  <Typography fontWeight="bold">{t("consult.close")}</Typography>
                </Button>
              )}

              {(consult.state === ConsultState.Closed || consult.state === ConsultState.Cancelled) && (
                <Button variant="outlined" sx={{ ml: 1 }} onClick={reopenConsult}>
                  <Typography fontWeight="bold">{t("consult.reopen")}</Typography>
                </Button>
              )}

              {consult.state !== ConsultState.Closed && consult.state !== ConsultState.Cancelled && (
                <Button variant="outlined" sx={{ ml: 1 }} onClick={cancelConsult} title={t("common.delete")}>
                  <DeleteForeverIcon />
                </Button>
              )}
            </>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

export default function ConsultPage() {
  const { organizationId, consultId } = useParams<string>();

  return (
    <ConsultProvider organizationId={organizationId} consultId={consultId}>
      <ConsultPageComponent />
    </ConsultProvider>
  );
}

function ConsultPageComponent() {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const { loading, consult, consultType, plan, refresh } = useConsultContext();

  const [returnUrl, setReturnUrl] = useState("");

  const [tabValue, setTabValue] = useState("1");

  const [editPatientData, setEditPatientData] = useState(false);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // logger.logTrace("ConsultPageComponent", "useEffect [user]", user);
    if (!user) {
      setReturnUrl(window.location.pathname);
    }
  }, [user]);

  if (loading || !consult || !user) {
    return (
      <>
        <LoadingFullScreen center={true} />
        <AuthentificationPopup open={!user} returnUrl={returnUrl} />
      </>
    );
  }

  const planTitle = plan ? plan.title[i18n.language] : "";
  const consultTypeTitle = consultType ? consultType.title[i18n.language] : "";

  const chats = consult.messages.filter((m) => m.type === ConsultMessageType.Chat);
  const messages = consult.messages.filter((m) => m.type === ConsultMessageType.Message);

  const isPractitioner = MeHelpers.isPractitionerOrAdmin(user);
  const isAdmin = MeHelpers.isOrgAdmin(user);
  const showAIMessages = chats.length > 0;
  const showDiagnosis = plan?.requirePractitioner;

  const editable = ConsultHelpers.isEditable(consult);
  const isActive = ConsultHelpers.isConsultActive(consult);
  const isMessageReadOnly = (messages.length === 0 && !isPractitioner) || !editable;

  const isShowPrescriptions = () => {
    if (isPractitioner) return true;
    let prescriptions = ConsultHelpers.getVisiblePrescriptions(isPractitioner, consult.prescriptions);
    return prescriptions.length > 0;
  };

  const getTabLabelText = (text: string, arr?: any[]) => {
    return arr && arr.length > 0 ? `${text} (${arr?.length})` : text;
  };

  if (editPatientData && plan) {
    return (
      <Container sx={{ marginTop: 5 }}>
        <CreateConsultProvider>
          <UpdateConsultData consultToEdit={consult} consultPlan={plan} setIsEdit={setEditPatientData} refresh={refresh} />
        </CreateConsultProvider>
      </Container>
    );
  }

  return (
    <Container sx={{ marginTop: 5 }}>
      <Breadcrumb consult={consult} />
      <Toolbar isPractitioner={isPractitioner} isOrgAdmin={isAdmin} />

      <Paper sx={{ p: 3, mt: 2 }} elevation={2}>
        <Typography variant="h2" mb={1}>
          {planTitle}
        </Typography>
        <Typography variant="h3" mb={1}>
          {consultTypeTitle}
        </Typography>

        <Stack direction="row" mt={2}>
          <Typography mr={1}>{t("consult.creation-date")}:</Typography>
          <Typography>{FormatHelpers.formatDateTimeShort(consult.createdOn)}</Typography>
        </Stack>

        <Stack direction="row">
          <Typography mr={1}>{t("consult.total")}:</Typography>
          <Typography>{FormatHelpers.formatMoney(consult.total, i18n.language)}</Typography>
        </Stack>
      </Paper>

      <Paper sx={{ p: 3, mt: 2 }} elevation={2}>
        {isPractitioner && (
          <Stack direction="row" alignContent="center" mb={2}>
            <InfoIcon />
            <Typography ml={1}>
              {t("consult.patient-language-info")} {consult?.language.toUpperCase()}
            </Typography>
          </Stack>
        )}

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTab} variant="scrollable" scrollButtons allowScrollButtonsMobile>
              <Tab label={t("consult.personal-infos")} value="1" />
              {showAIMessages && <Tab label={getTabLabelText(t("consult.chats"), chats)} value="2" />}
              {plan?.requirePractitioner && <Tab label={getTabLabelText(t("consult.messages"), messages)} value="3" />}
              {showDiagnosis && <Tab label={`${t("consult.diagnosis")}${consult.diagnosis ? " (1)" : ""}`} value="4" />}
              <Tab label={getTabLabelText(t("consult.prescriptions"), consult.prescriptions)} value="5" />
              {isAdmin && <Tab label={t("consult.admin-tab")} value="6" />}
            </TabList>
          </Box>

          <TabPanel value="1">
            {isPractitioner && isActive && (
              <Button variant="outlined" onClick={() => setEditPatientData(true)}>
                Edit
              </Button>
            )}
            <ConsultInformations consult={consult} plan={plan} />
          </TabPanel>

          {showAIMessages && (
            <TabPanel value="2">
              <Chat consult={consult} requestAdvice={false} readonly={true} messages={chats} chatType={ConsultMessageType.Chat} messagesChanged={refresh} />
            </TabPanel>
          )}

          {plan?.requirePractitioner && (
            <TabPanel value="3">
              <Chat consult={consult} requestAdvice={false} readonly={isMessageReadOnly} messages={messages} chatType={ConsultMessageType.Message} messagesChanged={refresh} />
            </TabPanel>
          )}

          {showDiagnosis && (
            <TabPanel value="4">
              <ConsultDiagnosis />
            </TabPanel>
          )}

          {isShowPrescriptions() && (
            <TabPanel value="5">
              <ConsultPrescriptions />
            </TabPanel>
          )}

          {isAdmin && (
            <TabPanel value="6">
              <ConsultTabAdmin />
            </TabPanel>
          )}
        </TabContext>
      </Paper>
    </Container>
  );
}

function UpdateConsultData({ consultToEdit, consultPlan, setIsEdit, refresh }: { consultToEdit: Consult; consultPlan: ConsultPlan; setIsEdit: Function; refresh: Function }) {
  const { t } = useTranslation();

  const { organizationId } = useParams<string>();
  const [loading, setLoading] = useState(true);
  const { setOrganization, setPlan, setCondition, documents, setDocuments, setQuestionnaireData, consult, setConsult } = useCreateConsultContext();

  useEffect(() => {
    const init = () => {
      if (organizationId && consultPlan && consultToEdit) {
        userService
          .getMeOrganization(organizationId)
          .then((org) => {
            if (org) {
              setOrganization(org);
              setPlan(consultPlan);
              setConsult({ ...consultToEdit });

              let docs = ConsultHelpers.getConsultDataImageList(consultToEdit);
              if (docs) setDocuments(docs);

              let conditionTemp = ConsultHelpers.getConsultDataCondition(consultToEdit);
              if (conditionTemp) setCondition(conditionTemp);
              else if (consultPlan.consultTypes.length <= 1) setCondition(consultPlan.consultTypes[0]);

              setQuestionnaireData(consultToEdit.data);
            } else {
              setIsEdit(false);
            }
          })
          .catch((e) => errorHelper.handleError(t, e))
          .finally(() => setLoading(false));
      }
    };

    init();
  }, [organizationId, consultPlan, consultToEdit]);

  const onContinue = async (data: any) => {
    if (organizationId && consult) {
      consultService
        .updateConsult(organizationId, { ...consult, data: data })
        .then((res) => {
          if (res) {
            refresh();
          }
        })
        .catch((e) => errorHelper.handleError(t, e))
        .finally(() => setIsEdit(false));
    }
  };

  if (loading) return <LoadingFullScreen center />;

  return <Questionnaire isEdit data={consultToEdit.data} documents={documents} onContinue={onContinue} />;
}
