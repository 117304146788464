import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSessionContext } from "../../contexts/SessionContext";
import { useEffect, useState } from "react";
import { Notify } from "../../services/Notify";
import archiveService from "../../services/Archive/ArchiveService";
import { useParams } from "react-router-dom";
import ArchiveConsultationDetail from "../../services/Archive/ArchiveConsultationDetail";
import React from "react";
import FileData from "../../services/Archive/FileData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MessageData from "../../services/Archive/MessageData";
import FormatHelpers from "../../services/FormatHelper";
import ImageDataModel from "../../services/Archive/ImageData";

export default function ArchiveConsultPage() {
  const { organization } = useSessionContext();
  const { consultId } = useParams<string>();

  const [consult, setConsult] = useState<ArchiveConsultationDetail | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function get() {
      if (organization && consultId) {
        try {
          const c = await archiveService.getConsult(organization.organization.id, consultId);
          setConsult(c);
          setLoading(false);
        } catch (e) {
          Notify.error("Erreur chargement");
        }
      }
    }

    get();
  }, [organization, consultId]);

  if (loading)
    return (
      <Container sx={{ marginTop: 5 }}>
        <Typography>Chargement en cours...</Typography>
      </Container>
    );

  if (!consult) return <Typography>Impossible d'obtenir la consultation</Typography>;

  return (
    <Container sx={{ marginTop: 5 }}>
      <Typography variant="h1">Général</Typography>
      <Typography>id: {consult.id}</Typography>
      <Typography>lockboxId: {consult.lockboxId}</Typography>

      <ImagesComponent images={consult.images} consultId={consult.id} token={consult.authToken} />

      <MessagesComponent messages={consult.messages} />

      <FileDataComponent title="Résultats" list={consult.results} consultId={consult.id} token={consult.authToken} type="results" />
      <FileDataComponent title="Prescriptions" list={consult.prescriptions} consultId={consult.id} token={consult.authToken} type="prescriptions" />
      <FileDataComponent title="Plan de traitements" list={consult.treatmentPlans} consultId={consult.id} token={consult.authToken} type="treatmentplans" />

      <Accordion sx={{ marginTop: 5 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Détails Raw
        </AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(consult, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

function ImagesComponent({ images, consultId, token }: { images: ImageDataModel[]; consultId: string; token: string }) {
  const { organization } = useSessionContext();

  return (
    <>
      <Typography mt={3} variant="h1">
        Images
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Date</TableCell>
            <TableCell>Par</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Attach</TableCell>
          </TableHead>
          <TableBody>
            {images.map((i) => {
              const fileName = "images/" + i.name;
              const url = `${process.env.REACT_APP_DERMSMART_API}/organizations/${organization?.organization.id}/archives/${consultId}/files?filename=${fileName}&token=${token}`;

              return (
                <React.Fragment key={i.uuid}>
                  <TableRow sx={{ borderTop: "1px solid #999" }}>
                    <TableCell>
                      {" "}
                      <Button target="_blank" href={url}>
                        {i.name}
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function MessagesComponent({ messages }: { messages: MessageData[] }) {
  return (
    <>
      <Typography mt={3} variant="h1">
        Messages
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Date</TableCell>
            <TableCell>Par</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Attach</TableCell>
          </TableHead>
          <TableBody>
            {messages.map((m) => {
              return (
                <React.Fragment key={m.uuid}>
                  <TableRow sx={{ borderTop: "1px solid #999" }}>
                    <TableCell>{FormatHelpers.formatDateTimeShort(m.createdAt)}</TableCell>
                    <TableCell>{m.createdByEmail}</TableCell>
                    <TableCell>{m.message}</TableCell>
                    <TableCell>{m.file ? "FILE" : ""}</TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function FileDataComponent({ title, list, consultId, token, type }: { title: string; list: FileData[]; consultId: string; token: string; type: string }) {
  const { organization } = useSessionContext();

  return (
    <>
      <Typography mt={3} mb={1} variant="h1">
        {title}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Date</TableCell>
            <TableCell>Fichier</TableCell>
          </TableHead>
          <TableBody>
            {list.map((r) => {
              const fileName = type + "/" + r.filename;
              const url = `${process.env.REACT_APP_DERMSMART_API}/organizations/${organization?.organization.id}/archives/${consultId}/files?filename=${fileName}&token=${token}`;

              return (
                <React.Fragment key={r.uuid}>
                  <TableRow>
                    <TableCell>{r.createdAt}</TableCell>
                    <TableCell>
                      <Button target="_blank" href={url}>
                        {r.filename}
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
