import { createContext, useContext, useState } from "react";
import MeOrganizationResponse from "../../services/Authentification/MeOrganizationResponse";
import ConsultPlan from "../../services/ConsultPlans/ConsultPlan";
import Consult from "../../services/Consults/Consult";

export interface ICreateConsultContext {
  organization: MeOrganizationResponse | undefined;
  setOrganization: (organization: MeOrganizationResponse | undefined) => void;

  plan: ConsultPlan | undefined;
  setPlan: (plan: ConsultPlan | undefined) => void;

  condition: string | undefined;
  setCondition: (condition: string | undefined) => void;

  documents: string[];
  setDocuments: (documents: string[]) => void;

  questionnaireData: any;
  setQuestionnaireData: (data: any) => void;

  consult: Consult | undefined;
  setConsult: (consult: Consult) => void;

  questionnaireCompleted: boolean;
  setQuestionnaireCompleted: (completed: boolean) => void;
}
const defaultState = {
  organization: undefined,
  setOrganization: () => {},

  plan: undefined,
  setPlan: () => {},

  condition: undefined,
  setCondition: () => {},

  documents: [],
  setDocuments: () => {},

  questionnaireData: {},
  setQuestionnaireData: () => {},

  consult: undefined,
  setConsult: () => {},

  questionnaireCompleted: false,
  setQuestionnaireCompleted: () => {},
};

const CreateConsultContext = createContext<ICreateConsultContext>(defaultState);

export const useCreateConsultContext = () => useContext(CreateConsultContext);

type Props = {
  children?: JSX.Element;
};

function CreateConsultProvider({ children }: Props) {
  const [organization, setOrganization] = useState<MeOrganizationResponse | undefined>(undefined);
  const [plan, setPlan] = useState<ConsultPlan | undefined>(undefined);
  const [condition, setCondition] = useState<string | undefined>(undefined);
  const [documents, setDocuments] = useState<string[]>([]);
  const [questionnaireData, setQuestionnaireData] = useState<any>({});
  const [consult, setConsult] = useState<Consult | undefined>(undefined);
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState<boolean>(false);

  return (
    <CreateConsultContext.Provider
      value={{
        organization,
        setOrganization,

        plan,
        setPlan,

        condition,
        setCondition,

        documents,
        setDocuments,

        questionnaireData,
        setQuestionnaireData,

        consult,
        setConsult,

        questionnaireCompleted,
        setQuestionnaireCompleted,
      }}
    >
      {children}
    </CreateConsultContext.Provider>
  );
}

export { CreateConsultProvider };
