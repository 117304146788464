import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./dot-falling.scss";
import "./chat.scss";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ChatMessage from "../../services/Consults/ConsultMessage";
import parse from "html-react-parser";
import { Notify } from "../../services/Notify";
import Consult from "../../services/Consults/Consult";
import consultService from "../../services/Consults/ConsultService";
import { useSessionContext } from "../../contexts/SessionContext";
import ConsultMessage from "../../services/Consults/ConsultMessage";
import { ConsultMessageType } from "../../services/Consults/ConsultMessageType";
import MeHelpers from "../../services/Authentification/MeHelpers";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import documentService from "../../services/Documents/DocumentService";
import useDocument from "../../services/Documents/useDocument";
import DescriptionIcon from "@mui/icons-material/Description";
import { ConsultMessageRequest } from "../../services/Consults/ChatRequest";
import UploadFileButton from "../../components/Documents/UploadFileButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import documentHelper from "../../services/Documents/DocumentHelper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Loading from "../../components/Loading";
import { Editor } from "@tinymce/tinymce-react";
import FormatHelpers from "../../services/FormatHelper";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
const messageBottomPadding = "5px";
const colorAssitant = "#fdf0d1";
const colorUser = "#b2dde6";

function MessageAssistantWorking() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box p={2} marginBottom={messageBottomPadding} bgcolor={colorAssitant} maxWidth="80px" borderRadius="15px" sx={{ borderTopLeftRadius: 0 }}>
          <Box className="stage">
            <Box className="dot-falling"></Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const MessageGridSX = { position: "relative", marginTop: "1rem" };
const MsgDateSX = { position: "absolute", top: -20, fontSize: "0.75rem" };

type MessageProps = {
  message: ChatMessage;
  index: number;
  documents?: Array<string>;
  organizationId?: string;
  editable: boolean;
};

function MessageAssistant({ message, index, documents, organizationId, editable }: MessageProps) {
  return (
    <Grid key={index} container>
      <Grid item xs={12} sx={MessageGridSX}>
        <Typography sx={{ ...MsgDateSX, left: 5 }}>{FormatHelpers.formatDateTimeShort(message.createdOn)}</Typography>
        <Box
          p={2}
          marginBottom={messageBottomPadding}
          bgcolor={colorAssitant}
          width="fit-content"
          minWidth={{ xs: "10%", lg: "10%" }}
          maxWidth={{ xs: "90%", lg: "75%" }}
          borderRadius="15px"
          sx={{ borderTopLeftRadius: 0 }}
        >
          <div className="msg-assistant">
            {message.type === ConsultMessageType.Chat ? <Markdown remarkPlugins={[remarkGfm]}>{message.message}</Markdown> : <Typography>{parse(message.message)}</Typography>}
          </div>

          {documents && documents.length > 0 && (
            <Stack direction="row" justifyContent="center" spacing={1}>
              {documents.map((doc) => {
                return <DocumentButton key={doc} organizationId={organizationId} documentId={doc} onDocumentRemoved={() => {}} readonly={editable} />;
              })}
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

function MessageUser({ message, index, documents, organizationId, editable }: MessageProps) {
  return (
    <Grid key={index} container>
      <Grid item xs={12} sx={MessageGridSX}>
        <Typography sx={{ ...MsgDateSX, right: 5 }}>{FormatHelpers.formatDateTimeShort(message.createdOn)}</Typography>
        <Box
          p={2}
          marginBottom={messageBottomPadding}
          bgcolor={colorUser}
          width="fit-content"
          minWidth={{ xs: "10%", lg: "10%" }}
          maxWidth={{ xs: "90%", lg: "75%" }}
          borderRadius="15px"
          sx={{ borderTopLeftRadius: 0, float: "right" }}
        >
          <Typography className="msg-user">{parse(message.message)}</Typography>

          {documents && documents.length > 0 && (
            <Stack direction="row" justifyContent="center" spacing={1}>
              {documents.map((doc) => {
                return <DocumentButton key={doc} organizationId={organizationId} documentId={doc} onDocumentRemoved={() => {}} readonly={editable} />;
              })}
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

function DocumentButton({
  documentId,
  organizationId,
  onDocumentRemoved,
  readonly,
}: {
  documentId?: string;
  organizationId?: string;
  readonly: boolean;
  onDocumentRemoved: (id: string) => void;
}) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLAnchorElement | null>(null);

  const { document, refresh, loading } = useDocument(organizationId, documentId);

  if (loading || !document) {
    return <></>;
  }

  const removeDocument = () => {
    if (document) {
      documentService
        .deleteDocument(document.organizationId, document.id)
        .then(() => {
          onDocumentRemoved(document.id);
          refresh();
        })
        .catch((e) => {
          Notify.error(`${t("chat.unexpected-error")}`);
          console.error(e);
        });
    }
  };

  const src = documentService.getDocumentSource(document.organizationId, document.id);

  return (
    <Stack border={1} borderColor="#038DAB" padding={1}>
      <Button title={document.filename} onClick={() => inputRef.current?.click()} sx={{ textAlign: "center", height: "60px" }} disabled={document.deleted}>
        {!document.deleted && <a type="file" style={{ display: "none" }} ref={inputRef} href={src} target="_blank" download />}
        {document.deleted ? <FolderOffIcon /> : documentHelper.isImage(document) ? <img src={src} style={{ height: "60px" }} /> : <DescriptionIcon />}
      </Button>

      {!document.deleted && (
        <Stack direction="row" width="100%" justifyContent="center">
          <Button size="small" onClick={() => inputRef.current?.click()}>
            <FileDownloadOutlinedIcon />
          </Button>
          <Button size="small" onClick={removeDocument} disabled={readonly}>
            <DeleteForeverIcon />
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

interface Props {
  consult: Consult | undefined;
  requestAdvice: boolean;

  readonly: boolean;
  messages?: ConsultMessage[];
  messagesChanged: () => Promise<void>;

  chatType: ConsultMessageType;
}
export default function Chat(props: Props) {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const initialized = useRef(false);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const inputRef = useRef<null | HTMLDivElement>(null);

  const [working, setWorking] = useState<boolean>(false);
  const [assistantWorking, setAssistantWorking] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const [questionDocuments, setQuestionDocuments] = useState<Array<string>>([]);
  const [messages, setMessages] = useState<Array<ChatMessage>>([]);

  const isPractitioner = MeHelpers.isPractitionerOrAdmin(user);

  useEffect(() => {
    async function getAdvice() {
      if (props.consult && !initialized.current) {
        initialized.current = true;
        await callChat([]);
      }
    }

    if (props.requestAdvice) {
      if (inputRef?.current) {
        getAdvice();
      }
    } else {
      if (props.messages) {
        setMessages(props.messages);
      } else {
        if (props.chatType === ConsultMessageType.Chat) {
          setMessages([{ type: props.chatType, role: "assistant", message: t("chat.nopicture-msg") }]);
        } else if (props.chatType === ConsultMessageType.Message) {
          setMessages([{ type: props.chatType, role: isPractitioner ? "user" : "assistant", message: t("chat.no-msg") }]);
        }
      }
    }
  }, [props.consult, props.requestAdvice, props.messages]);

  useEffect(() => {
    if (!props.readonly) {
      if (scrollRef?.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      inputRef?.current?.focus();
    }
  }, [messages]);

  const inputHandleKeyDown = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      askQuestion();
    }
  };

  const askQuestion = async () => {
    if (!props.consult) return;
    if (props.chatType === ConsultMessageType.Chat) {
      const newMessages = [...messages, { type: props.chatType, role: "user", message: question }];
      setMessages(newMessages);

      setQuestion("");

      await callChat(newMessages);
    } else if (props.chatType === ConsultMessageType.Message) {
      let documents: string[] = questionDocuments ?? [];
      sendMessage(props.consult, { role: isPractitioner ? "assistant" : "user", message: question, documents });
    }

    // setAssistantWorking(true);
    // setTimeout(() => {
    //   setMessages((old) => [...old, { role: "user", message: question }]);
    //   setMessages((old) => [...old, { role: "assistant", message: "réponse: " + question }]);
    //   setQuestion("");
    //   setAssistantWorking(false);
    // }, 50000);
  };

  const callChat = async (messages: ChatMessage[]) => {
    if (!props.consult) return;

    setAssistantWorking(true);
    try {
      const message = messages.length > 0 ? messages[messages.length - 1] : undefined;

      const response = await consultService.chat(props.consult, message);
      if (response) {
        // console.log("Chat response", response);
        setMessages((old) => [...old, { type: props.chatType, role: "assistant", message: response.text }]);
      }
    } catch (e) {
      handleError(e);
    }
    setAssistantWorking(false);
  };

  const sendMessage = async (consult: Consult, message: ConsultMessageRequest) => {
    if (user) {
      setWorking(true);

      consultService
        .createConsultMessage(consult, message)
        .then(async (data) => {
          if (data) {
            const newMessages = [...messages, { type: props.chatType, role: message.role, message: question, documents: message.documents }];
            setMessages(newMessages);
            setQuestion("");
            setQuestionDocuments([]);

            await props.messagesChanged();
          }
        })
        .catch(handleError)
        .finally(() => {
          setWorking(false);
        });
    }
  };

  const height = props.readonly ? "100%" : "60vh";

  const getMessageSide = (role: string) => {
    if (props.chatType === ConsultMessageType.Message && isPractitioner) {
      return role === "assistant" ? "user" : "assistant";
    }

    return role;
  };

  const onFileUpload = (files: FileList) => {
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      if (file.size > 20 * 1024 * 1024) {
        Notify.error(t("chat.invalid-size"));
        return;
      }
    }

    if (props.consult) {
      setWorking(true);
      documentService
        .uploadDocument(props.consult?.organizationId, files)
        .then((data) => {
          if (data) {
            let copy = [...questionDocuments];
            data.forEach((x) => copy.push(x.id));
            setQuestionDocuments(copy);
          }
        })
        .catch(handleError)
        .finally(() => {
          setWorking(false);
        });
    }
  };

  const onDocumentRemoved = (documentId: string) => {
    if (!props.readonly) {
      let copy = [...questionDocuments];
      var index = copy.indexOf(documentId);
      if (index >= 0) {
        copy.splice(index, 1);
      }
      setQuestionDocuments(copy);
    }
  };

  return (
    <Box sx={{ height: height, display: "flex", flexFlow: "column" }}>
      <Box sx={{ flex: "1 1 auto", overflowY: "auto", minHeight: "20vh" }}>
        {messages.length === 0 && !isPractitioner && props.readonly && (
          <Box textAlign="center">
            <Typography>{t("chat.empty-messages")}</Typography>
          </Box>
        )}
        {messages.map((message, index) => {
          const messageProps = { index: index, message: message, documents: message.documents, organizationId: props.consult?.organizationId, editable: props.readonly };
          return <div key={index}>{getMessageSide(message.role) === "user" ? <MessageUser {...messageProps} /> : <MessageAssistant {...messageProps} />}</div>;
        })}

        {assistantWorking && <MessageAssistantWorking />}

        <div ref={scrollRef}></div>
      </Box>

      {!props.readonly && (
        <Box sx={{ flex: "0 1 100px", width: "100%", marginTop: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {props.chatType !== ConsultMessageType.Chat && <UploadFileButton disabled={assistantWorking || working} onFileSelected={onFileUpload}></UploadFileButton>}

            {isPractitioner ? (
              <Box sx={{ width: "100%" }}>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  init={{
                    plugins: "anchor image link lists",
                    menubar: false,
                    toolbar: "undo redo | bold italic underline strikethrough | numlist bullist | removeformat",
                    tinycomments_mode: "embedded",
                    max_height: 250,
                    min_height: 100,
                    resize: false,
                  }}
                  value={question}
                  onEditorChange={(event: string) => {
                    setQuestion(event);
                  }}
                />
              </Box>
            ) : (
              <TextField
                ref={inputRef}
                label={t("chat.ask-question")}
                variant="outlined"
                sx={{ flex: 1 }}
                value={question}
                focused={true}
                disabled={assistantWorking || working}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQuestion(event.target.value);
                }}
                onKeyDown={inputHandleKeyDown}
              />
            )}

            <Button
              size="large"
              variant="contained"
              disabled={(question.length === 0 && questionDocuments.length === 0) || assistantWorking || working}
              sx={{ height: "100%", marginLeft: 1 }}
              onClick={askQuestion}
            >
              <SendIcon />
            </Button>
          </Box>

          {(working || questionDocuments.length > 0) && (
            <Box sx={{ marginTop: 2 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                {questionDocuments.map((document) => {
                  return (
                    <DocumentButton
                      key={document}
                      documentId={document}
                      organizationId={props.consult?.organizationId}
                      onDocumentRemoved={onDocumentRemoved}
                      readonly={props.readonly}
                    />
                  );
                })}

                {working && <Loading center={false} />}
              </Stack>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );

  function handleError(e: any) {
    Notify.error(`${t("chat.unexpected-error")}`);
    console.error(e);
  }
}
