import { Box, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";

export default function Page404() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Container sx={{ marginTop: 5 }}>
      <Stack sx={{ p: 2 }} direction="row">
        <Box ml={5}>
          <Typography variant="h1" gutterBottom mt={1}>
            {t("page404.title")}
          </Typography>
          <Typography variant="body2" fontSize={40}>
            404
          </Typography>
          <Typography variant="body1" gutterBottom mt={2}>
            {parse(t("page404.text", { path: location.pathname }))}
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}
