import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function NormeGraphique() {
  return (
    <Container maxWidth="lg">
      <hr />
      <Typography variant="h1" gutterBottom>
        h1. Heading
      </Typography>
      <hr />
      <Typography variant="h2" gutterBottom>
        h2. Heading
      </Typography>
      <hr />
      <Typography variant="h3" gutterBottom>
        h3. Heading
      </Typography>
      <hr />
      <Typography variant="h4" gutterBottom>
        h4. Heading
      </Typography>

      <hr />
      <Typography variant="subtitle1" gutterBottom>
        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </Typography>
      <hr />
      <Typography variant="subtitle2" gutterBottom>
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </Typography>
      <hr />
      <Typography variant="body1" gutterBottom>
        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate
        numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate
        numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Typography>

      <hr />
      <Typography variant="button" display="block" gutterBottom>
        button text
      </Typography>
      <hr />
      <Typography variant="caption" display="block" gutterBottom>
        caption text
      </Typography>
      <hr />
      <Typography variant="overline" display="block" gutterBottom>
        overline text
      </Typography>
    </Container>
  );
}
