import BaseApiClient from "../BaseApiClient";
import Questionnaire from "./Questionnaire";
import Field from "../Entities/Field";
import FieldValue from "../Entities/FieldValue";
import ConsultPlan from "./ConsultPlan";
import Section from "./Section";

class ConsultPlanService extends BaseApiClient {
  async getConsultPlans(organizationId: string): Promise<Array<ConsultPlan>> {
    const list = await this.get<Array<ConsultPlan>>(`${this.apiUrl}/organizations/${organizationId}/consultPlans`);
    if (!list) return [];

    let mapped = list.map((cp): ConsultPlan => this.mapConsultPlan(cp));
    return mapped;
  }

  async getConsultPlan(organizationId: string, planId: string, consultTypeId: string): Promise<ConsultPlan | undefined> {
    const data = await this.get<ConsultPlan>(`${this.apiUrl}/organizations/${organizationId}/consultPlans/${planId}?consultTypeId=${consultTypeId}`);
    if (!data) return undefined;

    return this.mapConsultPlan(data);
  }

  private mapConsultPlan(input: ConsultPlan): ConsultPlan {
    let questionnaire: Questionnaire | undefined = undefined;
    if (input.questionnaire) {
      questionnaire = this.mapQuestionnaire(input.questionnaire);
    }

    return {
      id: input.id,
      organizationId: input.organizationId,
      title: input.title,
      description: input.description,
      text: input.text,
      price: input.price,
      hasAi: input.hasAi,
      requirePractitioner: input.requirePractitioner,
      consultTypes: input.consultTypes,
      questionnaire: questionnaire,
    };
  }

  private mapQuestionnaire(input: Questionnaire): Questionnaire {
    return {
      title: input.title,
      notices: input.notices,
      sections: input.sections.map((s): Section => {
        return this.mapSection(s);
      }),
    };
  }

  private mapSection(input: Section): Section {
    return {
      title: input.title,
      fields: input.fields.map((f): Field => {
        return {
          id: f.id,
          type: f.type,
          label: f.label,
          placeHolder: f.placeHolder,
          values: this.mapFieldValues(f.values),
          units: this.mapFieldValues(f.units),
          required: f.required,
          condition: f.condition,
          properties: f.properties,
        };
      }),
    };
  }

  private mapFieldValues(input: Array<FieldValue>): Array<FieldValue> {
    return input.map((fv): FieldValue => {
      return {
        id: fv.id,
        label: fv.label,
      };
    });
  }
}

const consultPlanService = new ConsultPlanService();
export default consultPlanService;
