import { FormControlLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography } from "@mui/material";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropsDynamicField from "./PropsDynamicField";
import FieldHelpers from "../../services/Entities/FieldHelpers";

export default function DynamicFieldText(props: PropsDynamicField) {
  const { t } = useTranslation();
  const { field, data, update } = props;

  const [value, setValue] = useState<string>("");
  const [valueYesNo, setValueValueYesNo] = useState<boolean>(false);

  useEffect(() => {
    if (data.hasOwnProperty(field.id)) {
      let temp = data[field.id];
      setValue(temp);

      if (field.properties["conditionnal"] && temp) {
        setValueValueYesNo(true);
      }
    }
  }, [props.data]);

  const updateValue = (value: string) => {
    setValue(value);
    update(field.id, value);
  };

  const updateYesNo = (value: boolean) => {
    setValueValueYesNo(value);
    if (!value) {
      update(field.id, undefined);
    } else {
      update(field.id, "");
    }
  };

  const control = field.properties["control"];
  const minWidth = FieldHelpers.getPropertyNumber(field, "minWidth", 150);
  const conditionnal = field.properties["conditionnal"];
  const rowsText = field.properties["rows"];
  const rows = rowsText?.length > 0 ? parseInt(rowsText) : 1;
  const fullWidth = field.properties["fullWidth"] === "1";

  if (control === "radio") {
    return (
      <>
        <RadioGroup row value={value} onChange={(event) => updateValue(event.target.value)}>
          {field.values.map((fv) => {
            return <FormControlLabel key={fv.id} value={fv.id} control={<Radio />} label={fv.label[i18n.language]} />;
          })}
        </RadioGroup>
        {props.validated && props.error && <Typography color="#f00">{t("questionnaire.ErrorRequired")}</Typography>}
      </>
    );
  }

  if (control === "select") {
    return (
      <Select fullWidth={fullWidth} value={value} onChange={(event) => updateValue(event.target.value)} sx={{ minWidth: minWidth }} error={props.validated && props.error}>
        <MenuItem value=""></MenuItem>
        {field.values.map((fv) => {
          return (
            <MenuItem key={fv.id} value={fv.id}>
              {fv.label[i18n.language]}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  if (conditionnal === "1") {
    let placeHolder = undefined;
    if (field.placeHolder) placeHolder = field.placeHolder[i18n.language];

    return (
      <>
        <FormControlLabel
          sx={{ marginLeft: 0 }}
          label={field.label[i18n.language]}
          labelPlacement="start"
          control={<Switch color="primary" checked={valueYesNo} onChange={(event) => updateYesNo(event.target.checked)} />}
        />

        {valueYesNo && (
          <TextField
            fullWidth
            multiline={rows > 1}
            rows={rows}
            placeholder={placeHolder}
            value={value}
            onChange={(event) => updateValue(event.target.value)}
            error={props.field.required && props.validated && props.error}
          />
        )}
      </>
    );
  }

  return (
    <TextField
      fullWidth
      multiline={rows > 1}
      rows={rows}
      value={value}
      onChange={(event) => updateValue(event.target.value)}
      error={props.field.required && props.validated && props.error}
    />
  );
}
