import BaseApiClient from "../BaseApiClient";
import { Confidence } from "./Confidence";
import ImageAnalysisResponse from "./ImageAnalysisResponse";
import ImageAnalysisResult from "./ImageAnalysisResult";

class ImageAnalysisService extends BaseApiClient {
  async analzyeImages(formData: FormData): Promise<ImageAnalysisResult | undefined> {
    const response = await this.post<FormData, ImageAnalysisResponse>(`${this.apiUrl}/ImageAnalysis/Analyze`, formData);
    if (!response) return undefined;

    return { confidence: response.confidence as Confidence, resultDetails: response.resultDetails, documentIds: response.documentIds };
  }
}

const imageAnalysisService = new ImageAnalysisService();
export default imageAnalysisService;
