import { Box, Button, Dialog, DialogTitle, IconButton, Stack, SxProps, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export const CloseIconButtonSX = { position: "absolute", top: 1, right: 1 };

export function DialogCloseButton({ onClose, sx }: { onClose: () => void; sx?: SxProps<Theme> }) {
  const { t } = useTranslation();
  return (
    <IconButton sx={sx} onClick={onClose} title={t("common.close")}>
      <CloseIcon />
    </IconButton>
  );
}

export default function YesNoDialog(props: { open: boolean; onClose: () => void; onConfirm: () => void; label: string }) {
  const { t } = useTranslation();
  const { open, onClose, onConfirm, label } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        component: "form",
      }}
    >
      <DialogTitle sx={{ position: "relative" }}>
        <DialogCloseButton onClose={onClose} sx={CloseIconButtonSX} />
      </DialogTitle>

      <Box margin="1rem">
        <Typography>{label}</Typography>
        <Stack direction="row" justifyContent="space-evenly" marginTop="1rem">
          <Button variant="contained" onClick={onConfirm}>
            {t("common.yes")}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {t("common.no")}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
