import Consult from "../../services/Consults/Consult";
import { Stack, Table, TableBody, TableCell, TableRow } from "@mui/material";
import parse from "html-react-parser";
import React from "react";
import { FieldType } from "../../services/Entities/FieldType";
import FieldHelper from "../../services/Entities/FieldHelper";
import i18n from "../../i18n";
import ConsultPlan from "../../services/ConsultPlans/ConsultPlan";
import useDocuments from "../../services/Documents/useDocuments";
import { useTranslation } from "react-i18next";
import logger from "../../services/Logger";
import FieldHelpers from "../../services/Entities/FieldHelpers";
import documentService from "../../services/Documents/DocumentService";

interface Props {
  consult: Consult;
  plan: ConsultPlan | undefined;
}
export default function ConsultInformations(props: Props) {
  const { t } = useTranslation();

  logger.logTrace("ConsultInformations", "init", props);

  if (!props.plan || !props.plan.questionnaire) return <></>;

  return (
    <>
      <Table>
        <TableBody>
          {props.plan.questionnaire.sections.map((section, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: "bold", fontSize: "1.1em" }}>
                    {parse(section.title[i18n.language])}
                  </TableCell>
                </TableRow>

                {section.fields.map((field) => {
                  const label = field.label[i18n.language] ?? "";
                  const value: string = FieldHelper.getValue(field, props.consult.data, i18n.language);
                  //console.log(field.id, value, field);
                  const conditionnal = field.properties["conditionnal"];

                  if (!FieldHelpers.showField(field, props.consult.data)) return <></>;
                  if (field.type === FieldType.Label) return <></>;

                  return (
                    <TableRow key={field.id}>
                      <TableCell sx={{ paddingLeft: 4, width: "50%" }}>
                        {field.type === FieldType.ImageList ? <>{parse(field.placeHolder[i18n.language] ?? "")}</> : <>{parse(label)}</>}
                      </TableCell>
                      <TableCell>
                        {field.type === FieldType.ImageList ? (
                          <ConsultPageImageListField organizationId={props.consult.organizationId} value={value} />
                        ) : field.type === FieldType.YesNo ? (
                          <>{value === "No" ? t("common.no") : t("common.yes")}</>
                        ) : field.type === FieldType.Text && conditionnal && value === "No" ? (
                          <>{value === "No" ? t("common.no") : t("common.yes")}</>
                        ) : (
                          <>{value}</>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>

      {/* <br />
          <pre>{JSON.stringify(consult.data, null, 2)}</pre> */}
    </>
  );
}

function ConsultPageImageListField({ organizationId, value }: { organizationId: string; value: string }) {
  const { documents } = useDocuments(organizationId, value);

  if (documents.length === 0) return <></>;

  return (
    <Stack direction="row" spacing={1} padding={1} flexWrap="wrap" maxWidth={{ xs: "50%", lg: "75%" }}>
      {documents.map((document, index) => {
        const src = documentService.getDocumentSource(document.organizationId, document.id);

        return (
          <a key={index} href={src} target="_blank">
            <img src={documentService.getDocumentSource(document.organizationId, document.id)} height={100} alt={document.filename} />
          </a>
        );
      })}
    </Stack>
  );
}
