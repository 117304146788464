import { Box, Button, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Consult/CheckoutForm";
import i18n from "../../i18n";
import { useCreateConsultContext } from "./CreateConsultContext";
import Loading from "../../components/Loading";
import Promotion from "../../services/PromotionCodes/Promotion";
import promotionService from "../../services/PromotionCodes/PromotionService";
import errorHelper from "../../services/Core/ErrorHelper";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MathHelper from "../../services/Core/MathHelper";
import FormatHelpers from "../../services/FormatHelper";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PULICKEY || "");

interface Props {
  createConsult: (paymentMethodId: string | undefined, promotion: Promotion | undefined) => Promise<void>;
}
export default function PayConsult(props: Props) {
  const { t } = useTranslation();
  const { plan } = useCreateConsultContext();

  const [message, setMessage] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);

  const [promoCodeLoading, setPromoCodeLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<string>();
  const [promotion, setPromotion] = useState<Promotion>();
  const [promotionError, setPromotionError] = useState<string>();

  const discount = useMemo(() => {
    if (promotion && plan) {
      let temp = MathHelper.getPercentageAmount(plan.price, promotion.discountPercentage);
      return parseFloat(temp.toFixed(2));
    }
    return 0;
  }, [promotion]);

  const pay = async (paymentMethodId: string | undefined) => {
    if (!plan) return;

    setWorking(true);
    setMessage("");

    props.createConsult(paymentMethodId, promotion).finally(() => {
      setWorking(false);
    });
  };

  const onPromoCodeKeyDown = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getPromotion();
    }
  };

  const getPromotion = () => {
    if (plan?.organizationId && promoCode) {
      setPromoCodeLoading(true);
      promotionService
        .getPromotion(plan?.organizationId, promoCode)
        .then((data) => {
          if (data) {
            setPromotion(data);
            setPromoCode("");
            setPromotionError(undefined);
          } else {
            setPromotionError(t("payconsult.promo-code-error"));
          }
        })
        .catch((e) => errorHelper.handleError(t, e))
        .finally(() => setPromoCodeLoading(false));
    }
  };

  const removePromotion = () => {
    setPromotionError(undefined);
    setPromotion(undefined);
    setPromoCode("");
  };

  if (!plan) {
    return <></>;
  }

  const options: any = {
    mode: "payment",
    amount: plan.price * 100,
    currency: "cad",
    paymentMethodCreation: "manual",
    locale: i18n.language,
    appearance: {
      theme: "stripe",
    },
  };

  if (working)
    return (
      <Box py={15}>
        <Loading center={true} />
      </Box>
    );

  return (
    <Container sx={{ marginTop: 5 }}>
      <Typography variant="h1" mb={1}>
        {t("payconsult.title")}
      </Typography>

      <Paper sx={{ marginTop: 2, padding: 2 }}>
        <Typography variant="h2" mb={2}>
          {t("payconsult.promo-code")}
        </Typography>

        <Box marginTop="1rem" marginBottom="1rem">
          {promotion ? (
            <Box sx={{ display: "flex", border: "2px solid rgb(102, 187, 106)", borderRadius: 2.5 }}>
              <Box sx={PromotionIconStyle}>
                <CheckIcon />
              </Box>
              <Stack direction="column" margin="0.5rem" sx={{ flexGrow: 1 }}>
                <Typography sx={{ fontWeight: 600 }}>{promotion.code}</Typography>
                <Typography>
                  {t("payconsult.discount-text", {
                    0: promotion.discountPercentage,
                    1: FormatHelpers.formatMoney(discount, i18n.language),
                  })}
                </Typography>
              </Stack>
              <Button onClick={removePromotion}>
                <CloseIcon color="success" />
              </Button>
            </Box>
          ) : (
            <Box sx={{ flex: "0 1 100px", width: "100%", marginRight: "0.25rem", marginLeft: "0.25rem" }}>
              <Box sx={{ display: "flex", alignItems: "center", width: "95%" }}>
                <TextField
                  placeholder={t("payconsult.promo-code")}
                  variant="outlined"
                  sx={{ flex: 1 }}
                  value={promoCode}
                  focused={true}
                  label={promotionError}
                  error={promotionError ? true : false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPromoCode(event.target.value);
                  }}
                  onKeyDown={onPromoCodeKeyDown}
                />

                {promoCodeLoading ? (
                  <Loading center />
                ) : (
                  <Button size="large" variant="contained" sx={{ height: "100%", marginLeft: 1 }} onClick={getPromotion} disabled={!promoCode || promoCode.length === 0}>
                    {t("common.apply")}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Typography variant="h2" mb={2}>
          {t("payconsult.payment-infos")}
        </Typography>

        <Typography variant="h3" mb={2} sx={{ fontWeight: 600, fontSize: "1.2rem" }}>
          {`${t("common.price")}: ${FormatHelpers.formatMoney(plan.price - discount, i18n.language)}`}
        </Typography>

        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm pay={pay} message={message} disabled={promoCodeLoading} />
        </Elements>
      </Paper>
    </Container>
  );
}

const PromotionIconStyle = {
  padding: "0.5rem",
  margin: "0.5rem",
  backgroundColor: "green",
  borderRadius: 25,
  width: "40px",
  height: "40px",
  alignContent: "center",
  alignItems: "center",
};
