import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DashboardCard from "../../components/DashboardCard";
import i18n from "../../i18n";
import parse from "html-react-parser";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import useConsultTypes from "../../services/Consults/useConsultTypes";
import { useState } from "react";
import { useCreateConsultContext } from "./CreateConsultContext";

const boxMinHeight = 0;

function ConsultTypeLoading() {
  const list = [0, 1, 2];

  return (
    <Grid container spacing={3}>
      {list.map((index) => {
        return (
          <Grid key={index} item xs={12} md={6} lg={4}>
            <DashboardCard title="">
              <Box mb={3} minHeight={boxMinHeight}>
                <Skeleton variant="rectangular" width="100%" height={118} animation="pulse" />
              </Box>

              <Skeleton variant="rectangular" width="33%" animation="pulse" />
            </DashboardCard>
          </Grid>
        );
      })}
    </Grid>
  );
}

interface DialogProps {
  open: boolean;
  onChanged: (open: boolean) => void;
}
function DialogConditions({ open, onChanged }: DialogProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    onChanged(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        component: "form",
      }}
    >
      <DialogTitle>{t("desc-conditions.title")}</DialogTitle>
      <DialogContent>
        <Typography>{parse(t("desc-conditions.list"))}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  setConsultCondition: (consultConditionId: string, documentIds: string[]) => Promise<void>;
  identityCondition: () => void;
}
export default function SelectCondition(props: Props) {
  const { t } = useTranslation();
  const { organization, plan } = useCreateConsultContext();

  const { loading, consultTypes } = useConsultTypes(organization?.organization.id, plan?.id);

  const [working, setWorking] = useState(false);

  const [open, setOpen] = useState(false);
  const openChanged = (state: boolean) => {
    setOpen(state);
  };

  const setConsultCondition = async (consultConditionId: string) => {
    setWorking(true);
    props.setConsultCondition(consultConditionId, []).finally(() => setWorking(false));
  };

  return (
    <Container sx={{ marginTop: 5 }}>
      <Typography variant="h1" mb={1}>
        {t("create-consult.title")}
      </Typography>
      <Typography color="text.secondary" mb={4}>
        {t("create-consult.subtitle2")}
      </Typography>

      <Box>
        {(loading || working) && <ConsultTypeLoading />}

        {!loading && !working && (
          <Grid container spacing={3}>
            {consultTypes
              .filter((ct) => ct.id !== "skin-generic")
              .map((ct) => {
                const label = ct.label[i18n.language] ?? "";
                const description = ct.description[i18n.language] ?? "";

                return (
                  <Grid key={ct.id} item xs={12} md={6} lg={4}>
                    <DashboardCard title={label}>
                      <Box mb={3} minHeight={boxMinHeight}>
                        {description}
                      </Box>

                      <Button onClick={() => setConsultCondition(ct.id)}>{t("create-consult.start-consult")}</Button>
                    </DashboardCard>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Box>

      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} lg={12} sx={{ position: "relative" }}>
          <TroubleshootIcon sx={{ position: "absolute", top: 40, right: 10, fontSize: { xs: "2em", sm: "3em" } }} color="action" />

          <DashboardCard title={t("create-consult.identity-condition")}>
            <Box mt={3} mb={3}>
              {parse(t("create-consult.identify-description1a"))}
              <Link
                href="#"
                color="inherit"
                onClick={() => {
                  setOpen(true);
                }}
                marginLeft={0.5}
                marginRight={0.5}
              >
                {parse(t("create-consult.identify-description1b"))}
              </Link>
              {parse(t("create-consult.identify-description1c"))}
            </Box>

            <Button onClick={props.identityCondition}>{t("create-consult.identify-start")}</Button>
          </DashboardCard>
        </Grid>
      </Grid>

      <DialogConditions open={open} onChanged={openChanged} />
    </Container>
  );
}
