import { useTranslation } from "react-i18next";
import { useSessionContext } from "../../contexts/SessionContext";
import MeHelpers from "../../services/Authentification/MeHelpers";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import AssistedText from "../../components/AssistedText";
import consultService from "../../services/Consults/ConsultService";
import { Notify } from "../../services/Notify";
import parse from "html-react-parser";
import FormatHelpers from "../../services/FormatHelper";
import ConsultHelpers from "../../services/Consults/ConsultHelpers";
import ConsultPrescription from "../../services/Consults/ConsultPrescription";
import { ConsultPrescriptionState } from "../../services/Consults/ConsultPrescriptionState";
import ConsultPrescriptionStateComponent from "../../components/Consults/ConsultPrescriptionStateComponent";
import { useConsultContext } from "./ConsultContext";
import DocumentButton from "../../components/Documents/DocumentButton";
import { Place } from "../../services/Pharmacies/Pharmacy";
import logger from "../../services/Logger";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FaxStateComponent from "../../components/FaxStateComponent";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../../components/Loading";
import MuiPhoneNumber from "mui-phone-number";
import errorHelper from "../../services/Core/ErrorHelper";
import InfoIcon from "@mui/icons-material/Info";
import useCoordinate from "../../services/Geolocation/useCoordinate";
import pharmacyService from "../../services/Pharmacies/PharmacyService";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import pharmacyHelper from "../../services/Pharmacies/PharmacyHelper";
import YesNoDialog, { CloseIconButtonSX, DialogCloseButton } from "../../components/YesNoDialog";

function PrescriptionCard({
  organizationId,
  prescription,
  refresh,
  onEditClick,
  editable,
}: {
  organizationId: string;
  prescription: ConsultPrescription;
  refresh: () => Promise<void>;
  onEditClick: (val: string) => void;
  editable: boolean;
}) {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const isPractitioner = MeHelpers.isPractitioner(user);

  const [loading, setLoading] = useState(false);

  const [faxDetails, setFaxDetails] = useState<any | undefined>();
  const [faxDetailsPopupOpen, setFaxDetailsPopupOpen] = useState<boolean>(false);

  const coordinate = useCoordinate();
  const [pharmacyPopupOpen, setPharmacyPopupOpen] = useState(false);
  const [pharmacyOption, setPharmacyOption] = useState<string>();
  const [pharmacies, setPharmacies] = useState<Place[]>();
  const [chosenPharmacy, setChosenPharmacy] = useState<Place>();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [pharmacyFax, setPharmacyFax] = useState<string>("");
  const [faxError, setFaxError] = useState(false);

  useEffect(() => {
    if (prescription.faxId) {
      consultService
        .getConsultPrescriptionFaxDetails(organizationId, prescription.consultId, prescription.id, prescription.faxId)
        .then((data) => {
          if (data) {
            logger.logTrace("PrescriptionCard", "useEffect", data);
            setFaxDetails(data);
          }
        })
        .catch((e) => errorHelper.handleError(t, e));
    }
  }, [prescription]);

  const submit = async () => {
    if (user) {
      setLoading(true);
      await consultService
        .submitConsultPrescription(organizationId, prescription.consultId, prescription.id)
        .then(refresh)
        .catch((e) => {
          console.error("consult.submit prescription", e);
          Notify.error(`${t("common.unexpected-error")}`);
        })
        .finally(() => setLoading(false));
    }
  };

  const cancel = async () => {
    if (user) {
      setLoading(true);
      await consultService
        .cancelConsultPrescription(organizationId, prescription.consultId, prescription.id)
        .then(refresh)
        .catch((e) => {
          console.error("consult.cancel prescription", e);
          Notify.error(`${t("common.unexpected-error")}`);
        })
        .finally(() => setLoading(false));
    }
  };

  const reactivate = async () => {
    if (user) {
      setLoading(true);
      await consultService
        .reactivateConsultPrescription(organizationId, prescription.consultId, prescription.id)
        .then(refresh)
        .catch((e) => {
          console.error("consult.reactivate prescription", e);
          Notify.error(`${t("common.unexpected-error")}`);
        })
        .finally(() => setLoading(false));
    }
  };

  const sendFax = () => {
    logger.logInfo("PrescriptionCard", "sendFax", prescription.state);
    if (prescription.state === ConsultPrescriptionState.Approved) {
      resendFax();
    } else {
      approve();
    }
  };

  const approve = () => {
    if (!user) return;
    if (!canSendFax()) return;

    setLoading(true);
    consultService
      .approveConsultPrescription(organizationId, prescription.consultId, prescription.id, pharmacyFax, chosenPharmacy?.id)
      .then(refresh)
      .catch((e) => errorHelper.handleError(t, e))
      .finally(() => setLoading(false));
  };

  const resendFax = () => {
    if (!canSendFax()) return;

    setLoading(true);
    consultService
      .resendConsultPrescriptionFax(organizationId, prescription.consultId, prescription.id, pharmacyFax, chosenPharmacy?.id)
      .then(refresh)
      .catch((e) => errorHelper.handleError(t, e))
      .finally(() => setLoading(false));
  };

  const onSendFaxClick = () => {
    setPharmacyPopupOpen(true);
  };

  const closePopup = () => {
    setPharmacyPopupOpen(false);
    setPharmacyOption(undefined);
    setChosenPharmacy(undefined);
    setPharmacies(undefined);
    setFaxError(false);
    setPharmacyFax("");
  };

  const onApproveClick = () => {
    setPharmacyPopupOpen(true);
    approve();
  };

  const onPhoneChange = (value: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (typeof value === "string") {
      setPharmacyFax(value);
      console.log(value.length);
    } else {
      setPharmacyFax(value.target.value);
      console.log(value.target.value);
    }
  };

  const onPharmacyOptionClick = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === "0") {
      setPharmacyFax("");
      coordinate.fetch(fetchCoordinateCallback);
    } else {
      setPharmacies([]);
    }
    setPharmacyOption(value);
  };

  const fetchCoordinateCallback = (position: GeolocationPosition) => {
    console.log(position);
    pharmacyService
      .searchPharmacy("", position.coords.latitude, position.coords.longitude)
      .then((data) => {
        if (data) {
          setPharmacies(data.records);
        }
      })
      .catch((e) => errorHelper.handleError(t, e));
  };

  const canSendFax = () => {
    let res = chosenPharmacy || (pharmacyFax && pharmacyFax.length === 17);
    setFaxError(!res);
    return res;
  };

  if (loading) return <Loading center />;

  return (
    <Card>
      <CardContent>
        <Stack direction="row" flex="1" alignItems="center" mb={2}>
          <Typography color="text.secondary">
            {FormatHelpers.formatDateShort(prescription.createdOn)}, {prescription.createdByName}
          </Typography>

          <Box flex="1" textAlign="right">
            <ConsultPrescriptionStateComponent state={prescription.state} />
          </Box>

          {prescription.documentId && (
            <Box marginLeft="0.5rem" textAlign="right">
              <DocumentButton documentId={prescription.documentId} organizationId={organizationId} />
            </Box>
          )}

          {isPractitioner && prescription.state !== ConsultPrescriptionState.Cancelled && (
            <Box marginLeft="0.5rem" textAlign="right" title={t("common.cancel")}>
              <Button variant="outlined" onClick={cancel}>
                <DeleteForeverIcon />
              </Button>
            </Box>
          )}
        </Stack>

        <Divider />

        <Typography variant="body2" variantMapping={{ body2: "div" }}>
          {parse(prescription.text ?? "")}
        </Typography>
      </CardContent>

      {faxDetails && (
        <Stack alignItems={"flex-end"} alignContent="center">
          <Stack direction="row" marginRight="0.5rem" alignItems="center" textAlign="right">
            <Typography marginRight="0.5rem">Fax {faxDetails.faxNumber?.length > 0 ? "(" + faxDetails.faxNumber + ")" : ""}</Typography>

            {faxDetails.resultCode && <FaxStateComponent resultCode={faxDetails.resultCode} />}

            {isPractitioner && (
              <Button onClick={() => setFaxDetailsPopupOpen(true)}>
                <InfoIcon titleAccess={faxDetails.resultInfo} />
              </Button>
            )}
          </Stack>
        </Stack>
      )}

      {editable && (
        <CardActions>
          {isPractitioner && (
            <>
              {prescription.state === ConsultPrescriptionState.New && (
                <Button variant="outlined" onClick={() => onEditClick(prescription.id)}>
                  {t("common.edit")}
                </Button>
              )}
              {prescription.state === ConsultPrescriptionState.Cancelled && <Button onClick={reactivate}>{t("common.reactivate")}</Button>}
            </>
          )}

          <Box flex="1" textAlign="right">
            {prescription.state === ConsultPrescriptionState.New && isPractitioner && (
              <Button variant="outlined" onClick={submit}>
                {t("common.submit")}
              </Button>
            )}

            {prescription.documentId && (
              <>
                {prescription.state === ConsultPrescriptionState.Submitted && !isPractitioner && (
                  <Button variant="contained" onClick={onApproveClick}>
                    {t("consult.prescription-approve")}
                  </Button>
                )}

                {prescription.state === ConsultPrescriptionState.Approved && (
                  <Button variant="outlined" onClick={onSendFaxClick}>
                    {t("consult.prescription-send-fax")}
                  </Button>
                )}
              </>
            )}
          </Box>
        </CardActions>
      )}

      <Dialog
        open={pharmacyPopupOpen}
        onClose={closePopup}
        PaperProps={{
          component: "form",
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ position: "relative" }}>
          <DialogCloseButton onClose={closePopup} sx={CloseIconButtonSX} />
        </DialogTitle>

        <Box margin="1rem">
          <FormControl>
            <FormLabel>{t("consult.choose-pharmacy-option-title")}</FormLabel>
            <RadioGroup name="pharmacy-selector-options" onChange={onPharmacyOptionClick}>
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={
                  <span>
                    {t("consult.pharmacy-option-use-my-location")} <span style={{ color: "rgb(103, 109, 114)" }}>{t("consult.allow-geolocation")}</span>
                  </span>
                }
              />
              <FormControlLabel value="1" control={<Radio />} label={t("consult.pharmacy-option-enter-fax")} />
            </RadioGroup>
          </FormControl>

          {pharmacyOption && <Divider variant="middle" sx={{ marginTop: "1rem", marginBottom: "1rem" }} />}

          {pharmacyOption === "0" && pharmacies && coordinate.coordinate && (
            <>
              <Map
                style={{ width: "100%", height: 600 }}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                initialViewState={{ longitude: coordinate.coordinate.longitude, latitude: coordinate.coordinate.latitude, zoom: 12 }}
                mapStyle="mapbox://styles/mapbox/streets-v12"
              >
                {pharmacies.map((pharmacy) => {
                  return (
                    <Marker
                      style={{ cursor: "pointer" }}
                      key={pharmacy.id}
                      longitude={pharmacy.geoPoint.lng}
                      latitude={pharmacy.geoPoint.lat}
                      onClick={() => setChosenPharmacy(pharmacy)}
                    ></Marker>
                  );
                })}
              </Map>
              {chosenPharmacy && (
                <>
                  <Dialog
                    open={chosenPharmacy !== undefined}
                    onClose={() => setChosenPharmacy(undefined)}
                    maxWidth="lg"
                    PaperProps={{
                      component: "form",
                    }}
                  >
                    <DialogTitle sx={{ position: "relative" }}>
                      <DialogCloseButton onClose={() => setChosenPharmacy(undefined)} sx={CloseIconButtonSX} />
                    </DialogTitle>

                    <Box margin="1rem">
                      <Typography variant="h6">{chosenPharmacy.name}</Typography>

                      <Typography>{chosenPharmacy.address.streetAddress},</Typography>
                      <Typography>
                        {chosenPharmacy.address.place}, {chosenPharmacy.address.postalCode}
                      </Typography>
                      <Typography>{pharmacyHelper.getCountryRegionString(chosenPharmacy)}</Typography>
                      <Typography>{t("consult.pharmacy-fax-number-popup", { 0: chosenPharmacy.phones[0].number })}</Typography>

                      <Stack direction="row" justifyContent="space-evenly" marginTop="0.5rem">
                        <Button variant="contained" sx={{ marginRight: "0.5rem" }} onClick={() => setConfirmPopup(true)}>
                          {t("consult.pharmacy-send-fax-btn")}
                        </Button>
                        <Button variant="outlined" onClick={() => setChosenPharmacy(undefined)}>
                          {t("consult.pharmacy-cancel-btn")}
                        </Button>
                      </Stack>
                    </Box>
                  </Dialog>

                  <YesNoDialog
                    open={confirmPopup}
                    onClose={() => setConfirmPopup(false)}
                    onConfirm={sendFax}
                    label={t("consult.pharmacy-confirm-send-fax", { 0: chosenPharmacy.name })}
                  />
                </>
              )}
            </>
          )}

          {pharmacyOption === "1" && (
            <>
              <Typography>{t("consult.prescription-send-fax-title")}</Typography>
              <Box marginTop="1rem">
                <MuiPhoneNumber error={faxError} defaultCountry="ca" onChange={onPhoneChange} sx={{ marginRight: "1rem" }} countryCodeEditable={false} />
                <Button variant="outlined" onClick={sendFax}>
                  {t("common.submit")}
                </Button>
              </Box>
            </>
          )}
        </Box>

        <Divider variant="middle" />

        <Stack alignItems="end" margin="0.5rem">
          <Button size="small" variant="outlined" onClick={closePopup}>
            {t("common.cancel")}
          </Button>
        </Stack>
      </Dialog>

      <FaxDetailPopup faxDetails={faxDetails} open={faxDetailsPopupOpen} onClose={() => setFaxDetailsPopupOpen(false)} />
    </Card>
  );
}

export default function ConsultPrescriptions() {
  const { t } = useTranslation();

  const { user } = useSessionContext();
  const { consult, refresh } = useConsultContext();
  const isPractitioner = MeHelpers.isPractitioner(user);

  const [creation, setCreation] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentPrescription, setCurrentPrescription] = useState<ConsultPrescription>();
  const [prescriptions, setPrescriptions] = useState<Array<ConsultPrescription>>([]);

  const handleCreateClick = () => {
    setCreation(true);
    setPopupOpen(true);
  };

  const handleEditClick = (id: string) => {
    let prescriptionToEdit = consult!.prescriptions.find((x) => x.id === id);
    if (prescriptionToEdit) {
      setCurrentPrescription({ ...prescriptionToEdit });
      setPopupOpen(true);
    }
  };

  const handleSubmit = async (text: string) => {
    if (creation) {
      try {
        setCreation(false);
        setPopupOpen(false);

        if (creation) {
          await consultService.createConsultPrescription(consult!.organizationId, consult!.id, text);
        }
        await refresh();
      } catch (e) {
        console.error("consult.submit prescription", e);
        Notify.error(`${t("common.unexpected-error")}`);
      }
    } else if (currentPrescription) {
      consultService
        .updateConsultPrescriptionText(consult!.organizationId, consult!.id, currentPrescription.id, text)
        .then(async () => {
          await refresh();
        })
        .catch((e) => {
          console.error("consult.patch prescription", e);
          Notify.error(`${t("common.unexpected-error")}`);
        })
        .finally(() => {
          setCurrentPrescription(undefined);
          setPopupOpen(false);
        });
    }
  };

  const onPopupClose = (open: boolean) => {
    setCreation(false);
    setCurrentPrescription(undefined);
    setPopupOpen(open);
  };

  useEffect(() => {
    if (consult?.prescriptions) {
      let visiblePrescription = ConsultHelpers.getVisiblePrescriptions(isPractitioner, consult.prescriptions);
      setPrescriptions(visiblePrescription);
    }
  }, [consult?.prescriptions]);

  const editable = ConsultHelpers.isEditable(consult);

  if (!consult) return <></>;

  if (!isPractitioner && prescriptions.length === 0) {
    return (
      <Box textAlign="center">
        <Typography mb={2}>{t("consult.no-prescriptions")}</Typography>
      </Box>
    );
  }

  return (
    <>
      {prescriptions.length === 0 && <Typography>{t("consult.no-prescriptions")}</Typography>}

      {editable && isPractitioner && (
        <Stack direction="row" mt={2}>
          <Button variant="contained" onClick={handleCreateClick}>
            {t("consult.prescription-create")}
          </Button>
        </Stack>
      )}

      <Grid container direction="row" spacing={2} mt={2}>
        {prescriptions.map((prescription: ConsultPrescription) => {
          return (
            <Grid key={prescription.id} item xs={12}>
              <PrescriptionCard
                key={prescription.id}
                organizationId={consult.organizationId}
                prescription={prescription}
                refresh={refresh}
                onEditClick={handleEditClick}
                editable={editable}
              />
            </Grid>
          );
        })}
      </Grid>

      <AssistedText
        title={t("consult.prescription-title")}
        submitText={creation ? t("common.create") : t("common.save")}
        open={popupOpen}
        visibilityChanged={(open) => onPopupClose(open)}
        initialText={creation ? "" : currentPrescription?.text}
        submit={handleSubmit}
      />
    </>
  );
}

function FaxDetailPopup({ faxDetails, open, onClose }: { faxDetails: any; open: boolean; onClose: () => void }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        {t("consult.prescription-detailspopup-title")}
        <Stack sx={{ position: "absolute", top: 15, right: 15 }}>
          <IconButton onClick={onClose} title={t("common.close")}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box margin="1rem">
          <pre>{JSON.stringify(faxDetails, null, 2)}</pre>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
