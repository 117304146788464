import { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Notify } from "../../services/Notify";
import logger from "../../services/Logger";
import Loading from "../../components/Loading";

interface Props {
  pay: (paymentMethodId: string) => Promise<void>;
  message: string;
  disabled?: boolean;
}

export default function CheckoutForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const handleSubmit = async (event: any) => {
    if (!elements || !stripe) return;

    try {
      setMessage("");

      setLoading(true);

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setMessage(submitError.message ?? "");
        return;
      }

      // Create the PaymentMethod using the details collected by the Payment Element
      const paymentMethodResult = await stripe.createPaymentMethod({ elements });
      if (paymentMethodResult.error) {
        // This point is only reached if there's an immediate error when creating the PaymentMethod. Show the error to your customer (i.e. payment details incomplete)
        setMessage(paymentMethodResult.error.message ?? "");
        return;
      }

      logger.log("Payment Method", paymentMethodResult.paymentMethod);
      await props.pay(paymentMethodResult.paymentMethod.id);
    } catch (ex) {
      Notify.error(t("common.unexpected-error"));
    } finally {
      setLoading(false);
    }
  };

  const paymentElementOptions: any = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <Button disabled={loading || !stripe || !elements || props.disabled} id="submit" sx={{ marginTop: 2 }} variant="contained" onClick={handleSubmit}>
        <span id="button-text">{loading ? <Loading center={false} /> : t("payconsult.pay")}</span>
      </Button>

      {message && (
        <Typography color="error.main" mt={2}>
          {message}
        </Typography>
      )}
    </form>
  );
}
