import BaseApiClient from "../BaseApiClient";
import Me from "./Me";
import MeOrganizationResponse from "./MeOrganizationResponse";
import OrgAdminStats from "./OrgAdminStats";

class UserService extends BaseApiClient {
  async getMe(): Promise<Me | undefined> {
    let me = await this.get<Me>(`${this.apiUrl}/me`);
    if (me) {
      me.roles = me?.roles ?? [];
    }

    return me;
  }

  async getMeOrganization(organizationId: string): Promise<MeOrganizationResponse | undefined> {
    const response = await this.get<MeOrganizationResponse>(`${this.apiUrl}/me/organizations/${organizationId}`);
    return response;
  }

  async getMeDashboardStats(): Promise<OrgAdminStats | undefined> {
    const response = await this.get<OrgAdminStats>(`${this.apiUrl}/me/stats`);
    return response;
  }
}

const userService = new UserService();
export default userService;
