import { Box, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";

export default function LoadingFullScreen({ center }: { center: boolean }) {
  return (
    <Box height="100vh">
      <Stack direction="row" justifyContent={center ? "center" : "start"} alignItems="center" sx={{ width: 1, height: "100%" }}>
        <CircularProgress />
      </Stack>
    </Box>
  );
}
