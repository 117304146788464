import { Button } from "@mui/material";
import i18n from "i18next";

export default function ChangeLanguageButton() {
  const alternateLanguage = i18n.language === "fr" ? "en" : "fr";

  const changeLanguage = () => {
    i18n.changeLanguage(alternateLanguage);
  };

  return (
    <Button onClick={changeLanguage} color="inherit">
      {alternateLanguage}
    </Button>
  );
}
