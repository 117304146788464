import { Container, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useSessionContext } from "../../contexts/SessionContext";
import { useEffect, useState } from "react";
import { Notify } from "../../services/Notify";
import ArchiveConsultation from "../../services/Archive/ArchiveConsultation";
import archiveService from "../../services/Archive/ArchiveService";
import React from "react";
import { useNavigate } from "react-router-dom";
import FormatHelpers from "../../services/FormatHelper";

export default function ArchivesPage() {
  const navigate = useNavigate();
  const { organization } = useSessionContext();
  const [consults, setConsults] = useState<ArchiveConsultation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function get() {
      if (organization) {
        try {
          const list = await archiveService.getConsults(organization.organization.id);
          setConsults(list);
          setLoading(false);
        } catch (e) {
          Notify.error("Erreur chargement");
        }
      }
    }

    get();
  }, [organization]);

  if (loading)
    return (
      <Container sx={{ marginTop: 5 }}>
        <Typography>Chargement en cours...</Typography>
      </Container>
    );

  return (
    <Container sx={{ marginTop: 5 }}>
      <Table>
        <TableHead>
          <TableCell>id</TableCell>
          <TableCell>createdAt</TableCell>
          <TableCell>firstName</TableCell>
          <TableCell>lastName</TableCell>
          <TableCell>email</TableCell>
          <TableCell>phone</TableCell>
          <TableCell>shortId</TableCell>
          <TableCell>status</TableCell>
          <TableCell>type</TableCell>
        </TableHead>
        <TableBody>
          {consults.map((c) => {
            return (
              <React.Fragment key={c.id}>
                <TableRow onClick={() => navigate(`/organizations/${organization?.organization.id}/admin/archives/${c.id}`)}>
                  <TableCell>{c.id}</TableCell>
                  <TableCell>{FormatHelpers.formatDateTimeShort(c.createdAt)}</TableCell>
                  <TableCell>{c.firstName}</TableCell>
                  <TableCell>{c.lastName}</TableCell>
                  <TableCell>{c.email}</TableCell>
                  <TableCell>{c.phone}</TableCell>
                  <TableCell>{c.shortId}</TableCell>
                  <TableCell>{c.status}</TableCell>
                  <TableCell>{c.type}</TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
}
