import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import documentService from "./DocumentService";
import Document from "./Document";
import errorHelper from "../Core/ErrorHelper";
import logger from "../Logger";

export default function useDocuments(organizationId: string, documentIds: string) {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetch = () => {
    if (organizationId && documentIds && documentIds.length > 0) {
      documentService
        .getDocumentsFromIds(organizationId, documentIds.split(","))
        .then((data: Document[]) => {
          logger.logTrace("useDocuments", "fetch", data);
          setDocuments(data);
        })
        .catch((e) => errorHelper.handleError(t, e))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    logger.logTrace("useDocuments", "useEffect", { organizationId, documentIds });
    fetch();
  }, [organizationId, documentIds]);

  return { documents, refresh: fetch, loading };
}
