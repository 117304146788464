import BaseApiClient from "../BaseApiClient";
import Document from "./Document";

class DocumentService extends BaseApiClient {
  async uploadDocument(organizationId: string, files: FileList): Promise<Array<Document> | undefined> {
    const response = await this.postFile<Array<Document> | undefined>(`${this.apiUrl}/organizations/${organizationId}/documents`, files);
    if (!response) return undefined;
    return response;
  }

  async getDocument(organizationId: string, documentId: string): Promise<Document | undefined> {
    const response = await this.get<Document | undefined>(`${this.apiUrl}/organizations/${organizationId}/documents/${documentId}`);
    if (!response) return undefined;
    return response;
  }

  async deleteDocument(organizationId: string, documentId: string): Promise<boolean> {
    const response = await this.delete(`${this.apiUrl}/organizations/${organizationId}/documents/${documentId}`);
    return response ?? false;
  }

  getDocumentSource(organizationId: string, documentId: string) {
    return `${this.apiUrl}/organizations/${organizationId}/documents/${documentId}/download`;
  }

  async getDocumentsFromIds(organizationId: string, documentIds: Array<string>): Promise<Array<Document>> {
    if (documentIds.length === 0) return [];

    let ids = documentIds.join(",");
    const response = await this.get<Array<Document> | undefined>(`${this.apiUrl}/organizations/${organizationId}/documents/list?ids=${ids}`);
    if (!response) return [];
    return response as Document[];
  }
}

const documentService = new DocumentService();
export default documentService;
