import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import documentService from "./DocumentService";
import Document from "./Document";
import { Notify } from "../Notify";

export default function useDocument(organizationId?: string, documentId?: string) {
  const { t } = useTranslation();
  const [document, setDocument] = useState<Document>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetch = () => {
    if (organizationId && documentId) {
      documentService
        .getDocument(organizationId, documentId)
        .then((data) => {
          if (data) {
            setDocument(data);
          }
        })
        .catch((e) => {
          Notify.error(`${t("chat.unexpected-error")}`);
          console.error(e);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(fetch, [organizationId, documentId]);

  return { document, refresh: fetch, loading };
}
