import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useState } from "react";
import { t } from "i18next";
import feedbackService from "../services/Feedbacks/FeedbackService";

interface DialogProps {
  up: boolean;
  open: boolean;
  onChanged: (open: boolean) => void;
}
function FormDialog({ up, open, onChanged }: DialogProps) {
  const handleClose = () => {
    onChanged(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          console.log(formJson.text);
          await feedbackService.send(up, formJson.text);
          console.log("after");

          handleClose();
        },
      }}
    >
      <DialogTitle>{t("feedback.title")}</DialogTitle>
      <DialogContent>
        <TextField autoFocus required margin="dense" id="text" name="text" type="text" multiline rows={5} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("feedback.cancel")}</Button>
        <Button type="submit">{t("feedback.submit")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Feedback() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [up, setUp] = useState(false);

  const onUp = () => {
    setUp(true);
    setOpen(true);
  };

  const onDown = () => {
    setUp(false);
    setOpen(true);
  };

  const openChanged = (state: boolean) => {
    setOpen(state);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "fixed", top: "45%", right: 0 }}>
        <Box sx={{ marginRight: 0.1 }}>
          <Stack bgcolor={"primary.main"}>
            <Button sx={{ padding: 1, border: 0, margin: 0, minWidth: "32px" }} onClick={onUp}>
              <ThumbUpAltIcon sx={{ color: "#fff" }} />
            </Button>
            <Button sx={{ padding: 1, border: 0, minWidth: "32px" }} onClick={onDown}>
              <ThumbDownAltIcon sx={{ color: "#fff" }} />
            </Button>
          </Stack>
        </Box>
      </Box>

      <FormDialog open={open} onChanged={openChanged} up={up} />
    </Box>
  );
}
