import { FormControlLabel, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import PropsDynamicField from "./PropsDynamicField";
import i18n from "../../i18n";

export default function DynamicFieldYesNo(props: PropsDynamicField) {
  const { field, data, update } = props;

  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    if (data.hasOwnProperty(field.id)) {
      setValue(data[field.id] == "true");
    }
  }, [data]);

  const updateValue = (value: boolean) => {
    setValue(value);
    update(field.id, value ? "true" : "false");
  };

  return (
    <FormControlLabel
      sx={{ marginLeft: 0 }}
      label={field.label[i18n.language]}
      labelPlacement="start"
      control={<Switch color="primary" checked={value} onChange={(event) => updateValue(event.target.checked)} />}
    />
  );
}
