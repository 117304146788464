import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  initialText?: string;
  title?: string;
  submitText?: string;

  open: boolean;
  visibilityChanged: (open: boolean) => void;

  submit: (text: string) => Promise<void>;
}

export default function AssistedText(props: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (props.initialText || (!props.initialText && value !== "")) {
      setValue(props.initialText ?? "");
    }
  }, [props.initialText]);

  const close = () => {
    props.visibilityChanged(false);
  };

  const onEditorChange = (newValue: string, editor: any) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={props.open}
      onClose={close}
      fullWidth={true}
      maxWidth="lg"
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          await props.submit(value);
          close();
        },
      }}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>
        {/* <TextField autoFocus required margin="dense" id="text" name="text" type="text" multiline rows={15} fullWidth /> */}

        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          init={{
            plugins: "anchor image link lists",
            menubar: "",
            toolbar: "undo redo | blocks | bold italic underline strikethrough | link image | align | numlist bullist indent outdent | removeformat",
            tinycomments_mode: "embedded",
          }}
          value={value}
          onEditorChange={onEditorChange}
        />
      </DialogContent>
      <Box mr={2} mb={2}>
        <DialogActions>
          <Button onClick={close}>{t("common.cancel")}</Button>
          <Button variant="contained" type="submit" disabled={value.length === 0}>
            {props.submitText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
