import { Box, Button, Container, Skeleton, Stack, Typography } from "@mui/material";
import { CreateConsultProvider } from "./CreateConsultContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import DashboardCard from "../../components/DashboardCard";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import userService from "../../services/Authentification/UserService";
import authService from "../../services/Authentification/AuthService";
import { useCreateConsultContext } from "./CreateConsultContext";
import SelectPlan from "./SelectPlan";
import AuthentificationPopup from "../../components/AuthentificationPopup";
import PayConsult from "./PayConsult";
import { useSessionContext } from "../../contexts/SessionContext";
import Chat from "../Chat/Chat";
import { ConsultMessageType } from "../../services/Consults/ConsultMessageType";
import Questionnaire from "./Questionnaire";
import { Notify } from "../../services/Notify";
import ConsultHelpers from "../../services/Consults/ConsultHelpers";
import i18n from "../../i18n";
import Feedback from "../../components/Feedback";
import SelectCondition from "./SelectCondition";
import IdentifyCondition from "./IdentifyCondition";
import Promotion from "../../services/PromotionCodes/Promotion";
import Notices from "./Notices";

export default function CreateConsultPage() {
  return (
    <CreateConsultProvider>
      <CreateConsultOrchestrator />
    </CreateConsultProvider>
  );
}

function Error() {
  const { t } = useTranslation();

  return (
    <Container sx={{ marginTop: 5 }}>
      <Box mt={5}>
        <DashboardCard title={t("create-consult.error-title")}>
          <Typography mt={4} fontSize={18} variant="body1">
            {parse(t("create-consult.error-msg"))}
          </Typography>
        </DashboardCard>
      </Box>
    </Container>
  );
}

function Loading() {
  const list = [0];

  return (
    <Container sx={{ marginTop: 5 }}>
      <Stack marginLeft={{ md: 5, lg: 25 }} marginRight={{ md: 5, lg: 25 }}>
        {list.map((i) => {
          return (
            <Box key={i} mb={4}>
              <DashboardCard title="">
                <Box mb={3}>
                  <Skeleton variant="rectangular" width="100%" height={80} animation="pulse" />
                </Box>

                <Box mb={3}>
                  <Skeleton variant="rectangular" width="33%" animation="pulse" />
                </Box>

                <Skeleton variant="rectangular" width="33%" animation="pulse" />
              </DashboardCard>
            </Box>
          );
        })}
      </Stack>
    </Container>
  );
}

function CreateConsultOrchestrator() {
  const { t } = useTranslation();
  const { organizationId } = useParams<string>();
  const { user, ready } = useSessionContext();
  const { organization, setOrganization, plan, setPlan, condition, setCondition, documents, setDocuments, questionnaireData, setQuestionnaireData, consult, setConsult } =
    useCreateConsultContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);

  const [authOpen, setAuthOpen] = useState(false);
  const [returnUrl, setReturnUrl] = useState("");

  const StepSelectPlan = 1;
  const StepSelectCondition = 2;
  const StepIdentifyCondition = 3;
  const StepNotices = 4;
  const StepQuestionnaire = 5;
  const StepPayment = 6;
  const StepAssistant = 7;

  useEffect(() => {
    const init = async () => {
      if (ready && organizationId) {
        try {
          const org = await userService.getMeOrganization(organizationId);
          setOrganization(org);
          // console.log("MeOrganization", org);

          setStep(StepSelectPlan);
        } catch (e) {
          const axiosErr = e as AxiosError;
          if (axiosErr) authService.setError(axiosErr.response?.status ?? 500);
          console.error("CreateConsultPage.init() -> unexpected error", e);
        } finally {
          setLoading(false);
          //console.timeEnd("CreateConsultPage.init()");
        }
      }
    };

    init();
  }, [organizationId, ready, setPlan, setOrganization]);

  useEffect(() => {
    const init = async () => {
      if (organizationId && plan) {
        // console.log("Plan changed", plan);

        if (user) {
          hideLogin();

          //If there's only one condition, set the condition
          if (plan.consultTypes.length <= 1) {
            await setConsultCondition(plan.consultTypes[0], []);
          } else setStep(StepSelectCondition);
        } else {
          showLogin(`/consult/${organizationId}/?planId=${plan.id}`);
        }
      }
    };

    init();
  }, [organizationId, plan, user]);

  useEffect(() => {
    const init = async () => {
      if (step < StepQuestionnaire) {
        if (plan?.questionnaire && plan.questionnaire.notices.length > 0) {
          setStep(StepNotices);
        } else {
          setStep(StepQuestionnaire);
        }
      }
    };

    init();
  }, [condition]);

  const authOpenChanged = (state: boolean) => {
    setAuthOpen(state);
  };

  const showLogin = (returnUrl: string) => {
    setReturnUrl(returnUrl);
    setAuthOpen(true);
  };

  const hideLogin = () => {
    setReturnUrl("");
    setAuthOpen(false);
  };

  const identityCondition = () => {
    setStep(StepIdentifyCondition);
  };

  const setConsultCondition = async (consultConditionId: string, documentsIds: string[]) => {
    try {
      // console.log("setConsultCondition", consultConditionId, documentsIds);
      setDocuments(documentsIds);
      setCondition(consultConditionId);
    } catch (e) {
      Notify.error(`${t("common.unexpected-error")}`);
      console.error("consult.updateConsultCondition", e);
    }
  };

  const noticeAccepted = async () => {
    setStep(StepQuestionnaire);
  };

  const questionnaireCompleted = async (data: any) => {
    // console.log("Questionnaire completed.", data);
    setQuestionnaireData(data);

    if (plan!.price === 0) {
      await createConsultWithData(data, undefined);
    } else {
      setStep(StepPayment);
    }
  };

  const createConsult = async (paymentMethodId: string | undefined = undefined, promotion: Promotion | undefined = undefined) => {
    createConsultWithData(questionnaireData, paymentMethodId, promotion);
  };

  const createConsultWithData = async (data: any, paymentMethodId: string | undefined, promotion: Promotion | undefined = undefined) => {
    // console.log("Creating consult...");
    setLoading(true);
    try {
      const consult = await ConsultHelpers.createConsult(organization!.organization.id, plan!.id, paymentMethodId, i18n.language, condition, undefined, [], data, promotion?.code);
      if (consult) {
        // console.log(`Consult ${consult.id}`);
        setConsult(consult);

        if (plan?.hasAi) {
          // console.log(`Navigating to AI`);
          setStep(StepAssistant);
        } else {
          Notify.success(t("consult.msg-consult-submitted"));
          navigate(`/organizations/${consult?.organizationId}/consults/${consult?.id}`);
        }
      }
    } catch (e) {
      Notify.error(`${t("common.unexpected-error")}`);
      console.error("consult.createConsult", e);
    } finally {
      setLoading(false);
    }
  };

  const cancel = async () => {
    setPlan(undefined);
    setStep(StepSelectPlan);
  };

  if (loading) {
    return <Loading />;
  }
  if (!organization) {
    return <Error />;
  }

  // console.log("STEP", step, plan, organization, documents);

  const invoiceUrl = consult ? `${process.env.REACT_APP_DERMSMART_API}/organizations/${organization.organization.id}/consults/${consult.id}/invoice` : undefined;

  return (
    <Container sx={{ marginTop: 5 }}>
      {plan?.hasAi && invoiceUrl && consult && consult.total > 0 && (
        <Box sx={{ marginBottom: 2, padding: 2 }} textAlign="right">
          <Button variant="contained" href={invoiceUrl} target="_blank">
            {t("consult.display-invoice")}
          </Button>
        </Box>
      )}

      {step === StepSelectPlan && <SelectPlan />}
      {step === StepSelectCondition && <SelectCondition setConsultCondition={setConsultCondition} identityCondition={identityCondition} />}
      {step === StepIdentifyCondition && <IdentifyCondition setConsultCondition={setConsultCondition} cancel={cancel} />}
      {step === StepNotices && <Notices onContinue={noticeAccepted} />}
      {step === StepQuestionnaire && <Questionnaire documents={documents} onContinue={questionnaireCompleted} />}
      {step === StepPayment && <PayConsult createConsult={createConsult} />}
      {step === StepAssistant && <Chat consult={consult} requestAdvice={true} readonly={false} chatType={ConsultMessageType.Chat} messagesChanged={async () => {}} />}

      <Feedback />

      <AuthentificationPopup open={authOpen} visibilityChanged={authOpenChanged} returnUrl={returnUrl} onCancel={cancel} />
    </Container>
  );
}
