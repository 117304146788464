import BaseApiClient from "../BaseApiClient";
import Promotion from "./Promotion";

class PromotionService extends BaseApiClient {
  async getPromotion(organizationId: string, code: string): Promise<Promotion | undefined> {
    const response = await this.get<Promotion | undefined>(`${this.apiUrl}/organizations/${organizationId}/PromotionCodes/${code}`);
    if (!response) return undefined;
    return response;
  }
}

const promotionService = new PromotionService();
export default promotionService;
