import BaseApiClient from "../BaseApiClient";

interface CreateFeedback {
  up: boolean;
  text: string;
}

class FeedbackService extends BaseApiClient {
  async send(up: boolean, text: string) {
    const request: CreateFeedback = {
      up: up,
      text: text,
    };
    await this.postResponseless<CreateFeedback>(`${this.apiUrl}/feedbacks`, request);
  }
}

const feedbackService = new FeedbackService();
export default feedbackService;
