import TimeSpan from "./TimeSpan";

class DateHelper {
  getDifference(a: Date, b: Date): TimeSpan {
    let milliseconds = a.getTime() - b.getTime();
    return new TimeSpan(milliseconds);
  }
}

const dateHelper = new DateHelper();
export default dateHelper;
