export enum ConsultState {
  New = 0,
  Cancelled = 1,
  Assigned = 10,
  Processing = 20,
  Submitted = 30,
  Closed = 40,
}

export const ConsultStates = [ConsultState.New, ConsultState.Cancelled, ConsultState.Assigned, ConsultState.Processing, ConsultState.Submitted, ConsultState.Closed];
export const ActiveConsultStates = [ConsultState.New, ConsultState.Assigned, ConsultState.Processing, ConsultState.Submitted];
// export const InactiveConsultStates = [ConsultState.Closed, ConsultState.Cancelled];
