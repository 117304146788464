import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";

export default function Loading({ center }: { center: boolean }) {
  return (
    <Stack direction="row" justifyContent={center ? "center" : "start"} alignItems="center" sx={{ width: 1, height: "100%" }}>
      <CircularProgress />
    </Stack>
  );
}
