import { Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../../contexts/SessionContext";

export default function ProfilePage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  if (!user) return <></>;

  return (
    <Container sx={{ marginTop: 5 }}>
      <Stack direction="row" mb={2}>
        <Typography variant="h1">{t("profile.title")}</Typography>
      </Stack>

      <Paper sx={{ p: 3, mt: 2 }} elevation={2}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t("common.id")}</TableCell>
                <TableCell>{user.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("profile.email")}</TableCell>
                <TableCell>{user.email}</TableCell>
              </TableRow>
              {user.roles.length > 0 && (
                <TableRow>
                  <TableCell>{t("profile.organization")}</TableCell>
                  <TableCell>{user.organization.id}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>{t("profile.roles")}</TableCell>
                <TableCell>{user.roles.join()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}
