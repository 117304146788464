import { Box, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { useCreateConsultContext } from "./CreateConsultContext";
import useConsultPlan from "../../services/ConsultPlans/useConsultPlan";

interface Props {
  data?: any;
  onContinue: (data: any) => Promise<void>;
}
export default function Notices(props: Props) {
  const { t } = useTranslation();

  const { organization, plan: contextPlan, condition } = useCreateConsultContext();
  const { consultPlan: plan } = useConsultPlan(organization?.organization.id, contextPlan?.id, condition);

  return (
    <>
      {plan && plan.questionnaire && (
        <>
          {plan.questionnaire.notices.map((notice, index) => {
            const text = notice[i18n.language];
            return (
              <Box mt={2} key={index}>
                {parse(text)}
              </Box>
            );
          })}
        </>
      )}

      <Stack mt={4} direction="row" spacing={1}>
        <Button variant="contained" onClick={props.onContinue}>
          {t("common.continue")}
        </Button>
      </Stack>
    </>
  );
}
