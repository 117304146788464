import Field from "../../services/Entities/Field";
import FieldHelpers from "../../services/Entities/FieldHelpers";
import DynamicField from "./DynamicField";

interface Props {
  organizationId: string;

  fields: Array<Field>;
  data: any;
  update: (fieldId: string, value: string | undefined) => void;
  validated: boolean;
  fieldsInError: Array<string>;
}

export default function DynamicFieldSet(props: Props) {
  return (
    <>
      {props.fields.map((field) => {
        if (FieldHelpers.showField(field, props.data)) {
          return (
            <DynamicField
              key={field.id}
              organizationId={props.organizationId}
              field={field}
              data={props.data}
              update={props.update}
              validated={props.validated}
              error={props.fieldsInError.includes(field.id)}
            />
          );
        } else {
          return <div key={field.id}></div>;
        }
      })}
    </>
  );
}
