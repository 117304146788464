import { SnackbarMessage, enqueueSnackbar } from "notistack";

export class Notify {
  public static success(message: SnackbarMessage) {
    enqueueSnackbar(message, { variant: "success" });
  }

  public static error(message: SnackbarMessage) {
    enqueueSnackbar(message, { variant: "error" });
  }

  public static warning(message: SnackbarMessage) {
    enqueueSnackbar(message, { variant: "warning" });
  }

  public static info(message: SnackbarMessage) {
    enqueueSnackbar(message, { variant: "info" });
  }

  public static default(message: SnackbarMessage) {
    enqueueSnackbar(message);
  }
}
