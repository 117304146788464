import { Box, ButtonBase, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileData from "./FileData";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

interface ImageListProps {
  files: Array<FileData>;
  allowUpload?: boolean;
  onUpload?: () => void;
  onRemove?: (index: number) => void;
}
export default function ImageList(props: ImageListProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={1} padding={1}>
      {props.files.map((data, index) => (
        <div key={index}>
          {data.valid && (
            <Box position="relative">
              <img key={index} src={data.src} height={100} alt={data.file.name} />
              {props.onRemove && (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (props.onRemove) props.onRemove(index);
                  }}
                  sx={{ position: "absolute", top: 2, right: 2, borderWidth: "2px", borderColor: "#666", padding: "1px", backgroundColor: "#9999" }}
                >
                  <CloseIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
              )}
            </Box>
          )}
          {!data.valid && <Box>{t("image-analyzer.invalid-image")}</Box>}
        </div>
      ))}

      {props.allowUpload && (
        <Stack width={100} height={100} justifyContent="center" alignItems="center" border="dashed" borderColor="divider" sx={{ borderWidth: 2 }}>
          <ButtonBase onClick={props.onUpload}>
            <Stack alignItems="center">
              <AddIcon />
              <Typography mt={1}>{t("image-analyzer.upload")}</Typography>
            </Stack>
          </ButtonBase>
        </Stack>
      )}
    </Stack>
  );
}
