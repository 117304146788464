import BaseApiClient from "../BaseApiClient";
import ConsultType from "./ConsultType";

class ConsultTypeService extends BaseApiClient {
  async getConsultTypes(organizationId: string, consultPlanId: string): Promise<Array<ConsultType>> {
    const list = await this.get<Array<ConsultType>>(`${this.apiUrl}/organizations/${organizationId}/consulttypes?planId=${consultPlanId}`);
    return list || [];
  }

  async getConsultType(organizationId: string, id: string): Promise<ConsultType | undefined> {
    const response = await this.get<ConsultType>(`${this.apiUrl}/organizations/${organizationId}/consulttypes/${id}`);
    return response;
  }
}

const consultTypeService = new ConsultTypeService();
export default consultTypeService;
