import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConsultState } from "../../services/Consults/ConsultState";

export default function ConsultStateComponent({ state }: { state?: ConsultState }) {
  const { t } = useTranslation();

  const color =
    state === ConsultState.Assigned
      ? "info"
      : state === ConsultState.Processing
      ? "primary"
      : state === ConsultState.Closed
      ? "success"
      : state === ConsultState.Cancelled
      ? "error"
      : undefined;

  return <Chip label={t("consult.status-" + state)} color={color} variant="outlined" sx={{ minWidth: 105 }} />;
}
