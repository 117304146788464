import { useEffect, useState } from "react";
import consultPlanService from "./ConsultPlanService";
import { Notify } from "../Notify";
import { useTranslation } from "react-i18next";
import ConsultPlan from "./ConsultPlan";

export default function useConsultPlan(organizationId: string | undefined, planId: string | undefined, consultTypeId: string | undefined) {
  const { t } = useTranslation();

  const [consultPlan, setConsultPlan] = useState<ConsultPlan | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function refresh() {
      if (organizationId && planId && consultTypeId) {
        try {
          const consultPlans = await consultPlanService.getConsultPlan(organizationId, planId, consultTypeId);
          setConsultPlan(consultPlans);
          setLoading(false);
        } catch (e) {
          console.log("useConsultPlans", e);
          Notify.error(`${t("common.unexpected-error")}`);
        }
      }
    }

    refresh();
  }, [organizationId, planId, consultTypeId]);

  return {
    loading,
    consultPlan,
  };
}
