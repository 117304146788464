import Document from "./Document";

const ImageFormats = [".jpg", ".jpeg", ".webp", ".png"];
const DocumentFormats = [".pdf", ".doc", ".docx"];

export enum DocumentFormatType {
  Document,
  Image,
  All,
}

class DocumentHelper {
  getFormats(type: DocumentFormatType) {
    if (type === DocumentFormatType.Document) {
      return [...DocumentFormats];
    } else if (type === DocumentFormatType.Image) {
      return [...ImageFormats];
    }
    return [...ImageFormats, ...DocumentFormats];
  }

  isImage(document: Document): boolean {
    let isImage = false;
    for (let i = 0; i < ImageFormats.length; ++i) {
      if (document.filename.toLocaleLowerCase().endsWith(ImageFormats[i])) {
        return true;
      }
    }
    return isImage;
  }
}

const documentHelper = new DocumentHelper();
export default documentHelper;
