import { Box, Button, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import Loading from "../Loading";
import FileData from "./FileData";
import ImageList from "./ImageList";
import { t } from "i18next";

interface Props {
  title: string;
  subtitle: string;
  actionText: string;
  progressText: string;
  minImages: number;
  onChange: (files: Array<FileData>) => void;
}
export default function FileUpload(props: Props) {
  const [files, setFiles] = useState<Array<FileData>>([]);
  const [valid, setValid] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const inputRef = useRef<null | HTMLInputElement>(null);

  const imageMimeType = /image\/(png|jpg|jpeg|tiff|gif)/i;

  const onFilesSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;

    let list: Array<FileData> = files?.slice(0);
    let listIsValid = true;
    for (let i = 0; i < selectedFiles.length; ++i) {
      if (list.length >= props.minImages) continue;

      const file = selectedFiles[i];

      let valid = file.type.match(imageMimeType);
      let src: string = "";
      if (valid) {
        src = URL.createObjectURL(file);
        var fileData: FileData = { file, valid: valid ? true : false, src };
        list.push(fileData);
        setError(undefined);
      } else {
        listIsValid = false;
        setError("questionnaire.invalid-image-format");
      }
    }
    setFiles(list);
    setValid(listIsValid && list.length >= props.minImages);
  };

  const onRemoveImage = (index: number) => {
    // console.log("onRemoveImage", index);

    let newList = [...files];
    newList.splice(index, 1);
    setFiles(newList);
    setValid(newList.length >= props.minImages);
  };

  const onActionClicked = () => {
    setInProgress(true);
    props.onChange(files);
  };

  return (
    <Box border="dashed" borderColor="divider" sx={{ backgroundColor: "#eee" }}>
      <label htmlFor="upload-image">
        <Stack
          padding={5}
          paddingTop={3}
          paddingBottom={0}
          alignItems="center"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography color="text.secondary" fontSize="1.5rem" component="span">
            {props.title}
          </Typography>
          <Typography color="text.secondary" fontSize="1rem" component="span">
            {props.subtitle}
          </Typography>
          {error && (
            <Typography color="red" fontSize="0.9rem" component="span">
              {t(error)}
            </Typography>
          )}

          <input ref={inputRef} id="upload-image" hidden accept="image/*" type="file" multiple onChange={onFilesSelected} />
        </Stack>
      </label>

      <Stack justifyContent="center" alignItems="center" padding={2} paddingTop={2}>
        <ImageList
          files={files}
          allowUpload={files.length < 2}
          onRemove={onRemoveImage}
          onUpload={() => {
            inputRef.current?.click();
          }}
        />

        {!inProgress && (
          <Button size="large" variant="contained" onClick={onActionClicked} disabled={!valid} sx={{ marginTop: 2 }}>
            {props.actionText}
          </Button>
        )}
        {inProgress && (
          <Stack>
            <Typography mt={3} mb={1}>
              {props.progressText}
            </Typography>

            <Loading center={true} />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
