import { useTranslation } from "react-i18next";
import { useSessionContext } from "../../contexts/SessionContext";
import MeHelpers from "../../services/Authentification/MeHelpers";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import consultService from "../../services/Consults/ConsultService";
import { Notify } from "../../services/Notify";
import AssistedText from "../../components/AssistedText";
import parse from "html-react-parser";
import { useConsultContext } from "./ConsultContext";
import ConsultHelpers from "../../services/Consults/ConsultHelpers";

export default function ConsultDiagnosis() {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const { consult, refresh } = useConsultContext();

  const [popupOpen, setPopupOpen] = useState(false);

  const isPractitioner = MeHelpers.isPractitioner(user);

  const handleCreateClick = () => {
    setPopupOpen(true);
  };

  const handleCreateSubmit = async (text: string) => {
    try {
      setPopupOpen(false);

      await consultService.updateConsultDiagnosic(consult!.organizationId, consult!.id, text);
      await refresh();
    } catch (e) {
      console.error("consult.submit diagnosis", e);
      Notify.error(`${t("common.unexpected-error")}`);
    }
  };

  const editable = ConsultHelpers.isEditable(consult);
  const hasDiagnosis = consult?.diagnosis && consult?.diagnosis.length > 0;

  return (
    <>
      {hasDiagnosis ? (
        <Typography mb={2}>{parse(consult.diagnosis!)}</Typography>
      ) : (
        <Box textAlign="center">
          <Typography mb={2}>{t("consult.no-diagnosis")}</Typography>
        </Box>
      )}

      {editable && isPractitioner && (
        <>
          <Button variant="contained" onClick={handleCreateClick}>
            {t(hasDiagnosis ? "common.edit" : "consult.diagnosis-create")}
          </Button>

          <AssistedText
            title={t("consult.diagnosis-title")}
            submitText={t("common.save")}
            open={popupOpen}
            visibilityChanged={(open) => setPopupOpen(open)}
            initialText={consult?.diagnosis}
            submit={handleCreateSubmit}
          />
        </>
      )}
    </>
  );
}
