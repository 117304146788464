import { Box, Stack, Typography } from "@mui/material";
import { IUploadDocument } from "../../services/Documents/useUploadDocuments";
import DocumentList from "./DocumentList";
import parse from "html-react-parser";
import { useMemo } from "react";
import { t } from "i18next";
import Loading from "../Loading";

interface Props {
  title: string;
  iUploadDocument: IUploadDocument;
  accepts?: string;
  allowUpload?: boolean;
  error?: boolean;
}

export function DocumentUpload(props: Props) {
  const { title, iUploadDocument, accepts, allowUpload, error } = props;

  const sx = useMemo(() => {
    if (error) {
      return {
        backgroundColor: "#eee",
        borderColor: "red",
      };
    }
    return {
      backgroundColor: "#eee",
    };
  }, [error]);

  return (
    <Box border="dashed" borderColor="divider" sx={sx}>
      <label htmlFor="upload-image">
        <Stack
          padding={5}
          paddingTop={3}
          paddingBottom={0}
          alignItems="center"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography color="text.secondary" fontSize="1.5rem" component="span">
            {parse(title)}
          </Typography>
          {iUploadDocument.loading && <Loading center={true} />}
          {error && (
            <Typography color="red" component="span">
              {t("questionnaire.ErrorRequired")}
            </Typography>
          )}

          <input ref={iUploadDocument.inputRef} id="upload-image" hidden accept={accepts} type="file" multiple onChange={iUploadDocument.addFiles} />
        </Stack>
      </label>

      <Stack justifyContent="center" alignItems="center" padding={2} paddingTop={2}>
        <DocumentList
          documents={iUploadDocument.documents}
          allowUpload={allowUpload && !iUploadDocument.loading}
          onUploadClick={() => {
            iUploadDocument.inputRef.current?.click();
          }}
          onRemove={(documentId: string) => iUploadDocument.removeDocument(documentId)}
        />
      </Stack>
    </Box>
  );
}
