import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import type {} from "@mui/lab/themeAugmentation";

const theme = createTheme({
  palette: {
    primary: {
      main: "#038DAB",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    // action: {
    //   active: "#038DAB",
    //   hover: "#038DAB",
    //   focus: "#038DAB",
    //   selected: "#038DAB",
    // },
  },
  typography: {
    h1: {
      fontSize: "2.2rem",
      fontWeight: 400,
    },
    h2: {
      fontSize: "1.6rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.4rem",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 200,
      color: "#999",
    },
  },
  components: {
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: 20,
        },
      },
    },
  },
});

export default theme;
