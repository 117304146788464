import { useTranslation } from "react-i18next";
import { PaymentState } from "../../services/Payments/PaymentState";
import { Chip } from "@mui/material";
import { MuiColor } from "../../services/Core/MuiColors";

export default function PaymentStateComponent({ state }: { state: PaymentState }) {
  const { t } = useTranslation();

  const getColor = (): MuiColor => {
    switch (state) {
      case PaymentState.Paid:
        return "success";
      case PaymentState.Failed:
      case PaymentState.Disputed:
      case PaymentState.Refunded:
        return "error";
      case PaymentState.Pending:
        return "warning";
      default:
        return "info";
    }
  };

  return <Chip label={t("payment-state." + parseFloat(state.toString()))} color={getColor()} variant="outlined" sx={{ minWidth: 105 }} />;
}
