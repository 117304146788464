import { Box, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import authService from "../services/Authentification/AuthService";

export default function NetworkError() {
  const { t } = useTranslation();

  return (
    <Container sx={{ marginTop: 5 }}>
      <Stack sx={{ p: 2 }} direction="row">
        <WifiOffIcon sx={{ fontSize: 60 }} />

        <Box ml={5}>
          <Typography variant="h1" gutterBottom mt={1}>
            {t("network-error.title")}
          </Typography>

          <Typography variant="body1" gutterBottom mt={6}>
            {t("network-error.text")}
          </Typography>
          <Typography variant="body2" fontSize={40}>
            {authService.errorCode}
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}
