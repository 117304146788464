import Field from "../Entities/Field";
import ConsultPlan from "./ConsultPlan";

export default class ConsultPlanHelpers {
  public static getAllFields(consultPlan?: ConsultPlan): Array<Field> {
    let list: Array<Field> = [];

    if (consultPlan?.questionnaire) {
      for (const section of consultPlan.questionnaire.sections) {
        for (const field of section.fields) {
          list.push(field);
        }
      }
    }

    return list;
  }
}
