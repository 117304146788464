import { createContext, useContext, useEffect, useRef, useState } from "react";
import Me from "../services/Authentification/Me";
import MeOrganizationResponse from "../services/Authentification/MeOrganizationResponse";

export interface ISessionContext {
  initialized: boolean;

  consentGiven: boolean;
  consent: () => void;

  ready: boolean;
  setReady: (ready: boolean) => void;
  error: boolean;
  setError: (error: boolean) => void;

  organization: MeOrganizationResponse | undefined;
  setOrganization: (organization: MeOrganizationResponse | undefined) => void;

  user: Me | undefined;
  setUser: (me: Me | undefined) => void;
}
const defaultState = {
  initialized: false,

  consentGiven: false,
  consent: () => {},

  ready: false,
  setReady: () => {},
  error: false,
  setError: () => {},

  organization: undefined,
  setOrganization: () => {},

  user: undefined,
  setUser: () => {},
};

const SessionContext = createContext<ISessionContext>(defaultState);

export const useSessionContext = () => useContext(SessionContext);

type Props = {
  children?: JSX.Element;
};
function SessionProvider({ children }: Props) {
  const [initialized, setInitialized] = useState<boolean>(false);

  const [consentGiven, setConsentGiven] = useState<boolean>(false);

  const [ready, setReady] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [organization, setOrganization] = useState<MeOrganizationResponse | undefined>(undefined);
  const [user, setUser] = useState<Me | undefined>(undefined);

  const internalInitialized = useRef(false);

  function consent() {
    setConsentGiven(true);
    localStorage.setItem("consent", "consent-1.0");
  }

  useEffect(() => {
    const init = async () => {
      //logger.logTrace("SessionProvider", "useEffect");
      const consentValue = localStorage.getItem("consent");
      if (consentValue === "consent-1.0") {
        setConsentGiven(true);
      }

      setInitialized(true);
    };

    if (!internalInitialized.current) {
      internalInitialized.current = true;
      init();
    }
  }, []);

  return (
    <SessionContext.Provider
      value={{
        initialized,

        consentGiven,
        consent,

        ready,
        setReady,
        error,
        setError,

        organization,
        setOrganization,

        user,
        setUser,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export { SessionProvider };
