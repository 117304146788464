import BaseApiClient from "../BaseApiClient";
import ArchiveConsultation from "./ArchiveConsultation";
import ArchiveConsultationDetail from "./ArchiveConsultationDetail";

class ArchiveService extends BaseApiClient {
  async getConsults(organizationId: string): Promise<Array<ArchiveConsultation>> {
    const consults = await this.get<Array<ArchiveConsultation>>(`${this.apiUrl}/organizations/${organizationId}/archives`);

    if (consults) {
      for (let c of consults) {
        c.createdAt = new Date(c.createdAt);
      }
    }
    return consults || [];
  }

  async getConsult(organizationId: string, consultId: string): Promise<ArchiveConsultationDetail | undefined> {
    const consult = await this.get<ArchiveConsultationDetail>(`${this.apiUrl}/organizations/${organizationId}/archives/${consultId}`);

    if (consult) {
      for (let m of consult.messages) {
        m.createdAt = new Date(m.createdAt);
      }
    }

    return consult;
  }
}

const archiveService = new ArchiveService();
export default archiveService;
