import { useEffect, useState } from "react";
import { Notify } from "../Notify";
import { useTranslation } from "react-i18next";
import ConsultType from "./ConsultType";
import consultTypeService from "./ConsultTypeService";

export default function useConsultTypes(organizationId: string | undefined, consultPlanId: string | undefined) {
  const { t } = useTranslation();

  const [consultTypes, setConsultTypes] = useState<Array<ConsultType>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getConsultTypes() {
      // console.log(organizationId, consultPlanId);

      if (organizationId && consultPlanId) {
        try {
          const consultTypes = await consultTypeService.getConsultTypes(organizationId, consultPlanId);
          setConsultTypes(consultTypes);
          setLoading(false);
        } catch (e) {
          Notify.error(`${t("common.unexpected-error")}`);
        }
      }
    }

    getConsultTypes();
  }, [organizationId, consultPlanId]);

  return {
    loading,
    consultTypes,
  };
}
